.shortcuts {
  display: flex;
  flex-wrap: wrap;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  border: 1px solid #4a4d55;
  background-color: #000000;
  padding: 30px 0;
  position: absolute;
  z-index: 100;
  width: 100%;
  top: 0; }
  .shortcuts .shortcut-column {
    margin-right: 120px; }
  .shortcuts .shortcut-column:nth-of-type(1) {
    margin-left: 50px; }
  .shortcuts .shortcut-column:nth-of-type(3) {
    margin-right: 0; }
  .shortcuts .shortcut-type-name {
    font-size: 16px;
    color: #ffb209; }
  .shortcuts .shortcut-buttons-group {
    display: flex;
    flex-direction: row; }
  .shortcuts .shortcut-item {
    margin-top: 15px; }
    .shortcuts .shortcut-item label {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 11px;
      color: #a1aab7; }
    .shortcuts .shortcut-item .column-header {
      margin: 0 5px;
      font-size: 14px; }
  .shortcuts .shortcut-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 5px; }
  .shortcuts .shortcut-button {
    height: 42px;
    width: 42px;
    padding: 2px;
    margin: 5px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: #fff;
    font-size: 11px;
    display: flex;
    border: 2px solid white;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    font-style: normal;
    align-items: center;
    justify-content: center; }
  .shortcuts .short-text label {
    font-weight: 600;
    font-size: 24px; }
  .shortcuts .long-text label {
    font-size: 14px; }

.close-icon {
  height: 22px;
  width: 22px;
  position: absolute;
  right: 10px; }
