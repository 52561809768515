.drop-down-placeholder {
  color: white !important;
  line-height: 20px;
  opacity: 0.5; }

.text-ellipsis, .dropdown-ui-container_dropdown_content, .dropdown-ui-container_dropdown_content_list_item, .dropdown-ui-container_dropdown_content_search-field_wrapper input, .dropdown-ui-container_dropdown_content_search-field_wrapper input::placeholder, .dropdown-ui-container_dropdown_button_content, .dropdown-ui-container_dropdown_button_content_text, .dropdown-ui-container_dropdown_search-field, .collapse-config-container_header_label, .event-row_typeCategoryNotesCell_notes-text-field, .timeline-instance-container_header_title_text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.custom-scrollbar::-webkit-scrollbar, .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container::-webkit-scrollbar, .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .tabs-container.main-tabs-container::-webkit-scrollbar, .audio-assets-container::-webkit-scrollbar, .subs-assets-container::-webkit-scrollbar, .dropdown-ui-container_dropdown_content_list.scroll::-webkit-scrollbar, .events-table-container_content::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

.custom-scrollbar::-webkit-scrollbar-button, .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container::-webkit-scrollbar-button, .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .tabs-container.main-tabs-container::-webkit-scrollbar-button, .audio-assets-container::-webkit-scrollbar-button, .subs-assets-container::-webkit-scrollbar-button, .dropdown-ui-container_dropdown_content_list.scroll::-webkit-scrollbar-button, .events-table-container_content::-webkit-scrollbar-button {
  display: none; }

.custom-scrollbar::-webkit-scrollbar-thumb, .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container::-webkit-scrollbar-thumb, .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .tabs-container.main-tabs-container::-webkit-scrollbar-thumb, .audio-assets-container::-webkit-scrollbar-thumb, .subs-assets-container::-webkit-scrollbar-thumb, .dropdown-ui-container_dropdown_content_list.scroll::-webkit-scrollbar-thumb, .events-table-container_content::-webkit-scrollbar-thumb {
  border-radius: 3px;
  border-width: 0;
  border-style: none;
  background-color: rgba(141, 145, 154, 0.4); }

.custom-scrollbar::-webkit-scrollbar-track, .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container::-webkit-scrollbar-track, .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .tabs-container.main-tabs-container::-webkit-scrollbar-track, .audio-assets-container::-webkit-scrollbar-track, .subs-assets-container::-webkit-scrollbar-track, .dropdown-ui-container_dropdown_content_list.scroll::-webkit-scrollbar-track, .events-table-container_content::-webkit-scrollbar-track {
  background-color: transparent; }

.custom-scrollbar::-webkit-scrollbar-corner, .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container::-webkit-scrollbar-corner, .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .tabs-container.main-tabs-container::-webkit-scrollbar-corner, .audio-assets-container::-webkit-scrollbar-corner, .subs-assets-container::-webkit-scrollbar-corner, .dropdown-ui-container_dropdown_content_list.scroll::-webkit-scrollbar-corner, .events-table-container_content::-webkit-scrollbar-corner {
  height: 6px; }

.disabled, .player-time-picker-container_fields > input:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
  pointer-events: none; }

.unregistered-style, .configuration-radio-container .radio-container_radio.not-registered .radio-container_radio_title, .configuration-radio-container_audio-channels .checkbox-container_checkbox.not-registered .radio-container_radio_title, .asset-select-container_picker.unregistered .dropdown-ui-container_dropdown_button_content, .timeline-instance-container_header_title_text.unregistered {
  color: #F57617 !important; }

.markups-error-msg, .dropdown-ui-container_dropdown_content_list_item.error-msg, .tabs-component_tabs_tab-content.error-msg, .tabs-component_tabs_grid_tab-content.error-msg {
  color: rgba(255, 178, 9, 0.7) !important; }

.error-row, .dropdown-ui-container_error-message, .event-row_error_cell_content, .references-container_error {
  width: 100%;
  display: flex;
  height: 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
  color: rgba(255, 178, 9, 0.7); }

.text-container, .image-details-container_empty, .nonmedia-details-container_empty, .metadata-audio-container_configurations_data-tables_empty, .metadata-audio-container_configurations_data-tables_empty-configuration, .metadata-subtitles-container_empty, .video-details-container_empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 100px; }

@font-face {
  font-family: "Circular Std Black";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Circular/CircularStd-Black.otf") format("opentype"), url("https://one-ui.dmlib.de/static/client/static/fonts/CircularStd-Black.woff") format("woff"), url("https://one-ui.dmlib.in/static/client/static/fonts/CircularStd-Black.woff") format("woff"), url("https://one.bydeluxe.com/static/client/static/fonts/CircularStd-Black.woff") format("woff"); }

@font-face {
  font-family: "Circular Std Bold";
  font-style: normal;
  font-weight: 800;
  src: url("fonts/Circular/CircularStd-Bold.otf") format("opentype"), url("https://one-ui.dmlib.de/static/client/static/fonts/CircularStd-Bold.woff") format("woff"), url("https://one-ui.dmlib.in/static/client/static/fonts/CircularStd-Bold.woff") format("woff"), url("https://one.bydeluxe.com/static/client/static/fonts/CircularStd-Bold.woff") format("woff"); }

@font-face {
  font-family: "Circular Std Book";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Circular/CircularStd-Book.otf") format("opentype"), url("https://one-ui.dmlib.de/static/client/static/fonts/CircularStd-Book.woff") format("woff"), url("https://one-ui.dmlib.in/static/client/static/fonts/CircularStd-Book.woff") format("woff"), url("https://one.bydeluxe.com/static/client/static/fonts/CircularStd-Book.woff") format("woff"); }

@font-face {
  font-family: "Circular Std Medium";
  font-style: normal;
  font-weight: 600;
  src: url("fonts/Circular/CircularStd-Medium.otf") format("opentype"), url("https://one-ui.dmlib.de/static/client/static/fonts/CircularStd-Medium.woff") format("woff"), url("https://one-ui.dmlib.in/static/client/static/fonts/CircularStd-Medium.woff") format("woff"), url("https://one.bydeluxe.com/static/client/static/fonts/CircularStd-Medium.woff") format("woff"); }

.typoT1, .op_watermark-label {
  font-family: 'Circular Std Book';
  font-size: 30px !important;
  line-height: 1.2; }

.typoT2a {
  font-family: 'Circular Std Bold';
  font-size: 24px !important;
  letter-spacing: 2.25px; }

.typoT2b {
  font-family: 'Circular Std Book';
  font-size: 24px !important; }

.typoT3, .conformance-overlay-container .overlay-header {
  font-family: 'Circular Std Bold';
  font-size: 20px !important; }

.typo1, .op_player .op_player_wrapper_bitmovin-container_subtitles, .op_player .op_player_wrapper_bitmovin-container_subtitles_full-screen, .playback-setup-overlay_main-title, .loader-overlay .loader-text,
.video-not-found-overlay .video-not-found-text, .tmp-container, .tabs-component_tabs_tab-content, .tabs-component_content .ReactVirtualized__Table .ReactVirtualized__Table__headerRow .ReactVirtualized__Table__headerColumn, .tabs-component_content .ReactVirtualized__Table .ReactVirtualized__Table__rowColumn, .tabs-component_actions_count {
  font-family: 'Circular Std Book';
  font-size: 16px !important;
  line-height: 1.45; }

.typo2a, .media-display-popup-container_clock-dropdown_content .radio-container_radio, .audio-assets-container .assets-list_title, .audio-assets-container .assets-list_content_title, .subs-assets-container .assets-list_title, .subs-assets-container .assets-list_content_title, .details-container_error {
  font-family: 'Circular Std Bold';
  font-size: 14px !important; }

.typo2b, .media-display-popup-container_button-component, .playback-setup-overlay_description, .dropdown-ui-container_dropdown_content, .dropdown-ui-container_dropdown_content_list_item, .dropdown-ui-container_dropdown_content_search-field_wrapper input, .dropdown-ui-container_dropdown_content_search-field_wrapper input::placeholder, .dropdown-ui-container_dropdown_button_content, .dropdown-ui-container_dropdown_search-field, .input-container, .text-field-container_content_input, .collapse-config-container_header_label, .collapsible-container_content, .event-row_typeCategoryNotesCell_default-type {
  font-family: 'Circular Std Book';
  font-size: 14px !important;
  line-height: 1.5; }

.typo3, .player-time-picker-container_fields > input, .player-time-picker-container_fields_delimiter, .loop-control-bar-dropdown .op-loop-control-bar .time-picker,
.go-to-location-dropdown .op-control-bar-goTo .time-picker, .loop-control-bar-dropdown .op-loop-control-bar .time-picker input,
.go-to-location-dropdown .op-control-bar-goTo .time-picker input {
  font-family: 'Circular Std Book';
  font-size: 13px !important;
  letter-spacing: 2.5px; }

.typo4a, .dropdown-ui-container_label-container_label, .floating-warning-container_content, .audiolayer-label-container .assets-list_content_checkboxes .configuration-radio-container .radio-container_radio_title, .audiolayer-label-container .assets-list_content_checkboxes .configuration-radio-container_audio-channels > .checkbox-container_checkbox .radio-container_radio_title {
  font-family: 'Circular Std Bold';
  font-size: 11px !important;
  line-height: 1.67; }

.typo4b, .error-row, .dropdown-ui-container_error-message, .event-row_error_cell_content, .references-container_error, .subtitle-radio .radio-container_radio_title, .configuration-radio-container .radio-container_radio_title, .configuration-radio-container_audio-channels .checkbox-container_checkbox .radio-container_radio_title, .audio-assets-container .assets-list_content_checkboxes.empty > span, .subs-assets-container .assets-list_content_checkboxes.empty > span, .conformance-overlay-container .overlay-body, .timeline-container, .timeline-settings-container_popup, .timeline-settings-container_popup_content_settings .checkbox-container_checkbox .radio-container_radio_title {
  font-family: 'Circular Std Book';
  font-size: 11px !important; }

.typo5, .timelinerow-container_label {
  font-family: 'Circular Std Book';
  font-size: 9px !important;
  letter-spacing: 2px; }

.typo6 {
  font-family: 'Circular Std Book';
  font-size: 8px !important; }

.op_modules-wrapper_big-player-layout,
.op_modules-wrapper_small-player-layout {
  border: 1px solid #4a4d55; }
  .op_modules-wrapper_big-player-layout .tabs-container.main-tabs-container,
  .op_modules-wrapper_small-player-layout .tabs-container.main-tabs-container {
    margin-top: 20px; }

.op_modules-wrapper_small-player-layout {
  display: flex;
  flex-direction: row; }
  .op_modules-wrapper_small-player-layout .op_modules-wrapper_left-part {
    width: calc( 100% - 744px);
    min-width: 621px;
    overflow: hidden;
    border-right: 1px solid #4a4d55; }
  .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part {
    width: 742px;
    max-width: calc( 100% - 622px);
    margin-left: 0px;
    margin-right: 0px; }
    .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column {
      width: 100%; }
      .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container {
        overflow-x: auto; }

.op_modules-wrapper_big-player-layout {
  display: flex;
  flex-direction: column; }
  .op_modules-wrapper_big-player-layout .op_modules-wrapper_left-part {
    width: 100%; }
  .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part {
    width: 100%; }
    .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .op_modules-wrapper_timeline-wrapper {
      width: calc(100% - 742px);
      overflow: hidden;
      border-right: 1px solid #4a4d55; }
    .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .tabs-container.main-tabs-container {
      max-width: 742px;
      overflow-x: auto;
      margin-left: 0px;
      margin-right: 0px; }
    .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .full_screen-timeline {
      width: 100% !important; }

.op_modules-wrapper_left-part {
  display: flex;
  flex-direction: column;
  position: relative; }
  .op_modules-wrapper_left-part .op_player_wrapper {
    max-width: 926px !important; }

.op_modules-wrapper_right-part {
  display: flex;
  flex-direction: row; }
  .op_modules-wrapper_right-part_tabs-column {
    display: flex;
    flex-direction: column; }
    .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container {
      flex: 1; }
      .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container > .tabs-component {
        padding-right: 20px;
        padding-left: 20px; }

.op_player {
  background-color: #000000;
  min-width: 630px !important; }
  .op_player *,
  .op_player *:before,
  .op_player *:after {
    box-sizing: unset;
    /* we need this to fix bugs with integration with One UI*/ }
  .op_player.with-control-bar-hidden {
    cursor: none; }
  .op_player.full-screen .op_player_wrapper {
    max-width: 100% !important; }
    .op_player.full-screen .op_player_wrapper video {
      max-width: 100% !important; }
  .op_player.with-tabs:-webkit-full-screen {
    max-width: 100% !important; }
  .op_player .op_player_wrapper {
    margin: 0 auto; }
    .op_player .op_player_wrapper > .player-overlay {
      top: 0 !important;
      right: unset;
      bottom: unset;
      width: 100%;
      height: 100%; }
    .op_player .op_player_wrapper_bitmovin-container > video {
      position: unset; }
    .op_player .op_player_wrapper_bitmovin-container_subtitles {
      color: #fff; }
      .op_player .op_player_wrapper_bitmovin-container_subtitles_full-screen {
        color: #fff;
        font-size: 19.5px !important; }

.deluxe-player-container {
  overflow: visible !important; }

.deluxe-player-container .op_player {
  max-width: unset !important; }

.op_watermark-label {
  position: absolute;
  width: 100%;
  color: rgba(255, 255, 255, 0.8); }

.op_watermark-label.top {
  top: 10px; }

.op_watermark-label.bottom {
  bottom: 80px; }

.op_watermark-label.middle {
  top: calc(50% - 50px);
  transform: translateY(-50%); }

.op_watermark-label.diagonal {
  top: calc(50% - 50px);
  -ms-transform: translateY(-50%) rotate(-28deg);
  /* IE 9 */
  -webkit-transform: translateY(-50%) rotate(-28deg);
  /* Safari */
  transform: translateY(-50%) rotate(-28deg); }

.layout-and-shortcuts-tooltip-controls {
  display: flex;
  margin-bottom: 20px; }
  .layout-and-shortcuts-tooltip-controls_left {
    display: flex;
    flex: 1; }
  .layout-and-shortcuts-tooltip-controls_right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }
    .layout-and-shortcuts-tooltip-controls_right_shortcut-icon {
      display: flex;
      align-items: center; }
      .layout-and-shortcuts-tooltip-controls_right_shortcut-icon svg {
        position: relative;
        top: -1px;
        float: right;
        cursor: pointer; }
    .layout-and-shortcuts-tooltip-controls_right_delimiter {
      margin: 0 30px 0 34px;
      width: 1px;
      background-color: #8194b5; }

@media only screen and (max-width: 1440px) {
  .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .op_modules-wrapper_timeline-wrapper {
    width: 50%;
    overflow: hidden; }
  .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part_tabs-column {
    width: 50%; }
    .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container {
      overflow-x: auto; } }

@media only screen and (min-width: 1550px) and (max-width: 1919px) {
  .op_modules-wrapper_small-player-layout .op_modules-wrapper_left-part {
    width: 50%;
    min-width: 622px;
    overflow: hidden; }
  .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part {
    width: calc(50% - 2px); }
    .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column {
      width: 100%; }
      .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container {
        overflow-x: auto; } }

@media only screen and (min-width: 1920px) {
  .op_modules-wrapper_small-player-layout .op_modules-wrapper_left-part {
    width: calc( 100% - 744px); }
  .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part {
    width: 742px; }
    .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column {
      width: 100%; }
      .op_modules-wrapper_small-player-layout .op_modules-wrapper_right-part_tabs-column .tabs-container.main-tabs-container {
        overflow-x: auto; }
  .op_modules-wrapper_big-player-layout {
    display: flex;
    flex-direction: column; }
    .op_modules-wrapper_big-player-layout .op_modules-wrapper_left-part {
      width: 100%; }
    .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part {
      width: 100%; }
      .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .op_modules-wrapper_timeline-wrapper {
        width: calc( 100% - 744px);
        overflow: hidden; }
      .op_modules-wrapper_big-player-layout .op_modules-wrapper_right-part .tabs-container.main-tabs-container {
        max-width: 742px;
        overflow-x: auto; }
  .op_modules-wrapper_left-part .op_player_wrapper {
    max-width: 1097px !important; } }

.button-ui-container {
  box-shadow: none;
  border-width: 0px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  border-radius: 3px;
  padding: 11px 19px 11px 20px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  height: 40px; }
  .button-ui-container.small {
    padding: 5px 11px 5px 12px;
    height: 28px; }
  .button-ui-container:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3; }
  .button-ui-container.primary {
    background-color: #1F72F2; }
  .button-ui-container.primary:hover:not(:disabled) {
    background-color: #0F68C0; }
  .button-ui-container.primary.pressed {
    background-color: #0E5092 !important; }
  .button-ui-container.secondary {
    border-width: 1px;
    border-color: #1F72F2;
    color: #1F72F2; }
  .button-ui-container.secondary:hover:not(:disabled) {
    background-color: #253349; }
  .button-ui-container.secondary.pressed {
    background-color: #25426F; }
  .button-ui-container.secondary.negative {
    color: #E31F2E;
    border-color: #E31F2E; }
  .button-ui-container.secondary.negative:hover:not(:disabled) {
    background-color: #370105; }
  .button-ui-container.secondary.negative.pressed {
    background-color: #4B0308; }

.fab-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  background-color: #E31F2E;
  border: none !important;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12), 0 12px 12px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer; }
  .fab-button-container:hover:not(:disabled) {
    opacity: 0.8; }
  .fab-button-container:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.3; }

.options-grid-container {
  display: flex;
  min-height: 40px;
  align-items: center; }
  .options-grid-container_divider {
    width: 1px;
    height: 100%;
    box-sizing: border-box;
    opacity: 0.4;
    background-color: #8194B5;
    margin: 0 25px; }

.custom-button {
  min-width: 40px;
  padding: 2px 12px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: #fff;
  font-size: 11px;
  display: flex;
  border-radius: 11px;
  outline: none;
  cursor: pointer;
  font-style: normal;
  align-items: center;
  justify-content: center; }

.blueButton {
  background: #1f72f2;
  border: 1px solid #1f72f2; }

.blueButton:hover:not(:disabled),
.blueButton:hover:not(.not-hover) {
  background: #2c60ae;
  border: 1px solid #2c60ae; }

.greenButton {
  background: #0ab3a0;
  border: 1px solid #0ab3a0; }

.greenButton:hover {
  background: #2a9c8f;
  border: 1px solid #2a9c8f; }

.custom-button_label {
  flex: 1; }

.custom-button_icon {
  align-items: center;
  display: flex; }

.custom-button_icon.left-margin {
  margin-left: 5px; }

.custom-button.disabled, .player-time-picker-container_fields > input.custom-button:disabled {
  opacity: 0.7;
  cursor: not-allowed; }

.checkbox-container_checkbox {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 25px;
  align-items: center;
  justify-content: flex-start;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: #e7e7e7;
  font-size: 13px;
  cursor: pointer; }

.checkbox-container_checkbox > input[type='checkbox'] + .checkbox-container_checkbox_custom {
  width: 10px;
  height: 10px;
  border: 1px solid #1f72f2;
  background: transparent;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center; }

.checkbox-container_checkbox > input[type='checkbox']:checked + .checkbox-container_checkbox_custom {
  border: 1px solid #1f72f2; }

.checkbox-container_checkbox > input[type='checkbox'] + .checkbox-container_checkbox_custom .checkbox-container_checkbox_custom_check_mark {
  color: #1f72f2;
  display: flex;
  width: 6px;
  height: 6px;
  top: -8px;
  position: relative;
  font-weight: bold;
  font-size: 13px;
  left: -2px; }

.checkbox-container.disabled, .player-time-picker-container_fields > input.checkbox-container:disabled {
  opacity: 0.3; }

.radio-container_radio {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 25px;
  align-items: center;
  justify-content: flex-start;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: #e7e7e7;
  font-size: 13px;
  cursor: pointer; }

.radio-container_radio.disabled, .player-time-picker-container_fields > input.radio-container_radio:disabled {
  cursor: not-allowed;
  opacity: 0.3; }

.radio-container_radio.reverse {
  flex-direction: row-reverse; }

.radio-container_radio.reverse > input[type='checkbox'] + .radio-container_radio_custom {
  margin-right: 0px;
  margin-left: 5px; }

.radio-container_radio > input[type='checkbox'] + .radio-container_radio_custom {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #4a4d55;
  background-color: #1f2229;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center; }

.radio-container_radio > input[type='checkbox']:checked + .radio-container_radio_custom {
  border: 1px solid #1f72f2; }

.radio-container_radio > input[type='checkbox'] + .radio-container_radio_custom .radio-container_radio_custom_inner_circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #1f72f2;
  display: flex; }

.custom-select-dropdown-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 170px; }

.custom-select-dropdown-container_content {
  position: absolute;
  top: 38px;
  width: 100%;
  z-index: 10;
  border: 1.5px solid #1f72f2;
  border-radius: 2px;
  background: #1f2229;
  right: 0px;
  max-height: 220px;
  overflow-y: scroll;
  overflow-x: hidden; }

.custom-select-dropdown-container_content_item {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #FFF;
  text-indent: 5px;
  padding: 4px 0;
  cursor: pointer;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.custom-select-dropdown-container_content_item.active {
  background: #1f72f2; }

.button-component-container {
  display: flex;
  min-width: 135px;
  height: 32px;
  flex-direction: row;
  align-items: center;
  border: 1.5px solid #1f72f2;
  border-radius: 2px;
  cursor: pointer; }

.button-component-container_label {
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  flex: 1;
  padding-left: 5px;
  width: calc(100% - 20px); }

.button-component-container_icon {
  display: flex;
  height: 100%;
  width: 20px;
  align-items: center;
  justify-content: center; }

.button-component-container_label_span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.dropdown-content-container {
  background: #1f2229;
  border-radius: 2px;
  border: 1px solid #828282;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  color: #e7e7e7;
  font-size: 13px; }

.player-notifications-container .notification-container .notification .notification-message {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px; }

.player-notifications-container .notification-container .notification {
  padding: 15px !important; }

.player-notifications-container .notification-container .notification .notification-message .title,
.player-notifications-container .notification-container .notification .notification-message .message {
  text-align: left; }

.subtitle-radio .radio-container_radio_title {
  font-size: 12px !important; }

.subtitle-radio.not-available > input[type='checkbox'] + .radio-container_radio_custom,
.subtitle-radio.not-available > input[type='checkbox']:checked + .radio-container_radio_custom {
  border: 1px solid red !important; }

.subtitle-radio.not-available > input[type='checkbox']:checked + .radio-container_radio_custom .radio-container_radio_custom_inner_circle {
  background: red !important; }

.subtitle-radio.not-transformed > input[type='checkbox'] + .radio-container_radio_custom,
.subtitle-radio.not-transformed > input[type='checkbox']:checked + .radio-container_radio_custom {
  border: 1px solid orange !important; }

.subtitle-radio.not-transformed > input[type='checkbox']:checked + .radio-container_radio_custom .radio-container_radio_custom_inner_circle {
  background: orange !important; }

.configuration-radio-container {
  position: relative; }
  .configuration-radio-container_arrow {
    position: absolute;
    right: 0px;
    top: 6px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    .configuration-radio-container_arrow .fa-chevron-down {
      position: absolute;
      right: -18px;
      top: 8px;
      font-size: 10px; }
  .configuration-radio-container .radio-container_radio {
    position: unset !important; }
    .configuration-radio-container .radio-container_radio.not-staged > input[type='checkbox'] + .radio-container_radio_custom,
    .configuration-radio-container .radio-container_radio.not-staged > input[type='checkbox']:checked + .radio-container_radio_custom {
      border: 1px solid red !important; }
    .configuration-radio-container .radio-container_radio.not-staged > input[type='checkbox'] + .radio-container_radio_custom .radio-container_radio_custom_inner_circle {
      background: red !important; }
    .configuration-radio-container .radio-container_radio_title {
      font-size: 12px !important; }
    .configuration-radio-container .radio-container_radio_title {
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: calc(100% - 25px); }
  .configuration-radio-container_audio-channels {
    padding-left: 18px;
    display: flex;
    flex-direction: column;
    width: 100%; }
    .configuration-radio-container_audio-channels .checkbox-container {
      width: 155px;
      margin-bottom: 5px; }
      .configuration-radio-container_audio-channels .checkbox-container_checkbox.not-staged > input[type='checkbox'] + .checkbox-container_checkbox_custom,
      .configuration-radio-container_audio-channels .checkbox-container_checkbox.not-staged > input[type='checkbox']:checked + .checkbox-container_checkbox_custom {
        border: 1px solid red !important; }
      .configuration-radio-container_audio-channels .checkbox-container_checkbox.not-staged > input[type='checkbox'] + .checkbox-container_checkbox_custom .checkbox-container_checkbox_custom_check_mark {
        color: red !important; }
      .configuration-radio-container_audio-channels .checkbox-container_checkbox .radio-container_radio_title {
        font-size: 12px !important; }

.player-time-picker-container {
  display: flex;
  flex-direction: row; }
  .player-time-picker-container_fields {
    display: flex;
    justify-content: center;
    align-items: center; }
    .player-time-picker-container_fields > input {
      width: 25px;
      text-align: center;
      border: none;
      background: transparent;
      padding: 1px 0 !important;
      margin: 0 !important;
      color: #f2f3f4; }
      .player-time-picker-container_fields > input.milliseconds-input {
        width: 35px !important; }
    .player-time-picker-container_fields_delimiter {
      padding: 0 2.5px;
      margin-bottom: 0 !important; }
  .player-time-picker-container_arrows {
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .player-time-picker-container_arrows > .arrow-buttons {
      left: 5px; }

.op-control-bar {
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-width: 480px;
  font-size: 14px;
  color: #8e9ea4;
  height: 84px;
  display: flex;
  flex-direction: column;
  transition: opacity 0.5s; }

.op-control-bar.op_controls-bar_hidden {
  opacity: 0; }

.ttc-control-bar-dropdown {
  transition: opacity 0.5s; }

.ttc-control-bar-dropdown.op_controls-bar_hidden {
  opacity: 1; }

.op-control-bar .op_control-bar_bg {
  position: absolute;
  z-index: 0;
  top: -134px;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(31, 34, 41, 0), #1f2229); }

.op-control-bar-left-time {
  position: relative;
  bottom: 10px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  padding-left: 0;
  list-style: none;
  line-height: 14px;
  font-size: 14px;
  margin: 0px 25px;
  width: 100px;
  height: 40px;
  overflow: hidden;
  z-index: 15; }

.op-control-bar-button-bar *,
.op-control-bar-button-bar *:before,
.op-control-bar-button-bar *:after {
  box-sizing: unset;
  /* we need this to fix bugs with integration with One UI*/ }

.op-control-bar-button-bar {
  position: relative;
  z-index: 1;
  text-align: center;
  height: 30px;
  padding: 17px 15px 18px 15px;
  display: flex;
  justify-content: space-between; }

.op-control-bar-button-bar div {
  cursor: pointer;
  display: flex;
  align-items: center; }

.op-control-bar-button-bar .op-control-bar-left-button-bar svg,
.op-control-bar-button-bar .op-control-bar-right-button-bar svg {
  padding: 0 12px;
  width: 18px; }

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='number'] {
  -moz-appearance: textfield; }

.op-speed-rate-dropdown ul {
  list-style: none;
  padding: 3px 15px;
  margin: 0px; }

.op-control-bar-volume-range {
  display: flex; }

.op_player.op_player_full-screen .dropdown-content.op-control-bar-volumeDropdown {
  -ms-transform: translate(-5px, 7px);
  -webkit-transform: translate(-5px, 7px);
  transform: translate(-5px, 7px); }

.op-control-bar-volumeDropdown .dropdown-content-wrapper {
  display: flex;
  flex-direction: row-reverse;
  width: 95px;
  transform: rotate(-90deg);
  position: relative;
  top: 80px;
  left: -28px; }

.op_player.op_player_full-screen .dropdown-content.op-control-bar-volumeDropdown .dropdown-content-wrapper {
  left: -26px; }

.op-control-bar-volumeDropdown .dropdown-content-container {
  border: 0;
  padding: 13px 8px;
  width: 100%; }

.op-control-bar-volumeDropdown .dropdown-content-container + svg {
  -ms-transform: rotate(90deg) translate(10px, -1px);
  /* IE 9 */
  -webkit-transform: rotate(90deg) translate(10px, -1px);
  /* Safari */
  transform: rotate(90deg) translate(10px, -1px);
  width: 11px; }

.op-control-bar-volumeDropdown .ttc-slide-bar.op-control-bar-volume-range {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #434c5c; }

.op-control-bar-volumeDropdown .ttc-slide-bar.op-control-bar-volume-range .ttc-slide-bar-thumb {
  display: none; }

.op-control-bar-volumeDropdown .ttc-slide-bar.op-control-bar-volume-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 0;
  width: 0; }

.op-control-bar-volumeDropdown .ttc-slide-bar.op-control-bar-volume-range::-moz-range-thumb,
.op-control-bar-volumeDropdown .ttc-slide-bar.op-control-bar-volume-range.ttc-slide-bar::-ms-thumb {
  border: none;
  height: 0;
  width: 0; }

.op-control-bar-volumeDropdown input {
  border-radius: 0;
  box-shadow: none; }

.op-control-bar-volumeDropdown input[type='range'].ttc-slide-bar::-moz-range-thumb {
  /* Remove thumb from the range element in Mozilla FF */
  width: 0px !important; }

.op-control-bar-volumeDropdown-button {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.op_seek-bar {
  position: relative;
  z-index: 2;
  padding: 0 25px;
  box-sizing: border-box; }

.op_seek-bar.disabled, .player-time-picker-container_fields > input.op_seek-bar:disabled {
  pointer-events: none; }

.op_seek-bar div.ttc-slide-bar.ttc-control-bar-seek-range {
  width: calc(100% - 50px);
  background-color: rgba(255, 255, 255, 0.3); }

.op_seek-bar .ttc-slide-bar.ttc-control-bar-seek-range {
  height: 4px;
  box-shadow: none;
  background: none;
  border-radius: 0;
  cursor: pointer; }

.op_seek-bar input[type='range'].ttc-slide-bar {
  padding: 8px 0;
  top: -6px;
  border: 0; }

.op_seek-bar input[type='range'].ttc-slide-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 0;
  width: 0; }

.op_seek-bar input[type='range'].ttc-slide-bar::-moz-range-thumb,
.op_seek-bar input[type='range'].ttc-slide-bar::-ms-thumb {
  border: none;
  height: 0;
  width: 0; }

.op_seek-bar .ttc-slide-bar.ttc-control-bar-seek-range .ttc-slide-bar-thumb {
  width: 12px;
  height: 12px;
  top: -4px;
  border-radius: 50%;
  -ms-transform: translateX(-6px);
  /* IE 9 */
  -webkit-transform: translateX(-6px);
  /* Safari */
  transform: translateX(-6px); }

.dropdown-content.go-to-location-dropdown .dropdown-content-wrapper {
  position: relative; }

.dropdown-content.go-to-location-dropdown .dropdown-content-container {
  border: none;
  padding: 12px 21px 12px 21px; }

.dropdown-content.go-to-location-dropdown .dropdown-content-container + svg {
  position: absolute;
  bottom: -26px;
  left: 50%;
  width: 38px;
  height: 32px;
  -ms-transform: translateX(-50%);
  /* IE 9 */
  -webkit-transform: translateX(-50%);
  /* Safari */
  transform: translateX(-50%); }

.go-to-location-dropdown .op-control-bar-goTo {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  align-items: center;
  padding: 0;
  justify-content: center; }

.go-to-location-dropdown .op-control-bar-goTo span {
  font-size: 16px;
  margin-bottom: 10px;
  color: #fff; }

.go-to-location-dropdown .op-control-bar-goTo-input {
  text-align: center;
  background-color: transparent;
  color: white;
  height: 18px;
  width: 43px;
  border: 1px solid grey;
  border-radius: 2px; }

.go-to-location-dropdown .op-control-bar-goTo .time-picker .input-number {
  margin: 0; }

.go-to-location-dropdown .op-control-bar-goTo .time-picker .arrow-buttons {
  top: 1px;
  left: 10px; }

.dropdown-content.loop-control-bar-dropdown .dropdown-content-wrapper {
  position: relative; }

.dropdown-content.loop-control-bar-dropdown .dropdown-content-container + svg {
  position: absolute;
  bottom: -27px;
  left: 50%;
  width: 38px;
  height: 32px;
  -ms-transform: translateX(-50%);
  /* IE 9 */
  -webkit-transform: translateX(-50%);
  /* Safari */
  transform: translateX(-50%); }

.dropdown-content.loop-control-bar-dropdown .dropdown-content-container {
  border: none;
  padding: 10px 18px 18px 18px; }

.loop-control-bar-dropdown.ttc-control-bar-dropdown li {
  padding: 0; }

.loop-control-bar-dropdown .op-loop-control-bar .time-picker,
.go-to-location-dropdown .op-control-bar-goTo .time-picker {
  letter-spacing: unset !important; }
  .loop-control-bar-dropdown .op-loop-control-bar .time-picker input,
  .go-to-location-dropdown .op-control-bar-goTo .time-picker input {
    width: 25px !important;
    height: auto !important;
    text-align: center !important;
    letter-spacing: unset !important; }
  .loop-control-bar-dropdown .op-loop-control-bar .time-picker .arrow-buttons,
  .go-to-location-dropdown .op-control-bar-goTo .time-picker .arrow-buttons {
    top: -2px; }
  .loop-control-bar-dropdown .op-loop-control-bar .time-picker.time-picker-container,
  .go-to-location-dropdown .op-control-bar-goTo .time-picker.time-picker-container {
    box-shadow: none !important; }

.loop-control-bar-dropdown .op-loop-control-bar .title {
  font-size: 16px;
  padding-bottom: 5px; }

.loop-control-bar-dropdown .op-loop-control-bar .time-picker_title {
  padding: 4px 0; }

.dropdown-content.speed-rate-dropdown .dropdown-content-wrapper {
  padding-bottom: 30px;
  position: relative; }

.dropdown-content.speed-rate-dropdown .dropdown-content-container {
  border: none;
  padding: 10px 21px 14px 21px; }

.dropdown-content.speed-rate-dropdown .dropdown-content-container + svg {
  position: absolute;
  bottom: 3px;
  left: 50%;
  width: 38px;
  height: 32px;
  -ms-transform: translateX(-50%);
  /* IE 9 */
  -webkit-transform: translateX(-50%);
  /* Safari */
  transform: translateX(-50%); }

.dropdown-content.speed-rate-dropdown .op-speed-rate-dropdown .op_speed-rate-dropdown_title {
  font-size: 16px;
  padding-bottom: 5px; }

.dropdown-content.speed-rate-dropdown .op-speed-rate-dropdown .op_speed-rate-dropdown_speed-rate-options {
  display: flex;
  flex-direction: row; }

.dropdown-content.speed-rate-dropdown .radio-container_radio_title {
  color: #999fa9; }

.dropdown-content.speed-rate-dropdown .op-speed-rate-dropdown .op_speed-rate-dropdown_speed-rate-options li {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  padding: 2px 2px; }

.dropdown-content.speed-rate-dropdown .speed-rate-dropdown_delimiter {
  background-color: #a1aab7;
  width: 1px;
  margin: 3px 18px; }

.dropdown-content.speed-rate-dropdown .op_speed-rate-dropdown_speed-rate-options_column {
  padding-top: 3px; }

.dropdown-content.speed-rate-dropdown .op_speed-rate-dropdown_speed-rate-options_column-title {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #999fa9;
  padding-bottom: 5px; }

.op-control-bar .subtitles-icon {
  position: relative;
  top: 2px; }

@media only screen and (max-width: 1720px) {
  .op-control-bar-button-bar .op-control-bar-left-button-bar svg,
  .op-control-bar-button-bar .op-control-bar-right-button-bar svg {
    padding: 0 10px;
    width: 14px; } }

.media-display-popup-container {
  cursor: pointer; }
  .media-display-popup-container_clock-dropdown {
    transform: translate(0, -15px); }
    .media-display-popup-container_clock-dropdown .dropdown-content-wrapper .dropdown-content-container {
      border: none;
      padding: 5px 10px !important; }
  .media-display-popup-container_button-component {
    color: #fff; }

.dropdown-content.audio-subtitles-dropdown .dropdown-content-wrapper {
  padding-bottom: 39px;
  position: relative; }

.dropdown-content.audio-subtitles-dropdown .dropdown-content-container {
  border: none;
  padding: 14px 21px; }

.dropdown-content.audio-subtitles-dropdown .dropdown-content-container + svg {
  position: absolute;
  bottom: 8px;
  right: 13px;
  width: 52px;
  height: 32px; }

.dropdown-content.audio-subtitles-dropdown .audio-subtitles-dropdown_content {
  display: flex;
  flex-direction: row;
  max-height: 410px; }

.dropdown-content.audio-subtitles-dropdown .audio-subtitles-dropdown_delimiter {
  background-color: #a1aab7;
  width: 1px;
  margin: 3px 20px 3px 2px; }

.assets-list {
  width: 240px; }

.audio-subtitles-icon {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.audio-assets-container {
  overflow-y: scroll;
  overflow-x: hidden; }
  .audio-assets-container .assets-list_title {
    color: #e7e7e7; }
  .audio-assets-container .assets-list_content {
    padding-top: 10px; }
    .audio-assets-container .assets-list_content_checkboxes {
      padding-top: 5px;
      min-height: 80px; }
      .audio-assets-container .assets-list_content_checkboxes.empty {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0px; }
    .audio-assets-container .assets-list_content_title {
      color: #a1aab7; }

.subs-assets-container {
  overflow-y: scroll;
  overflow-x: hidden; }
  .subs-assets-container .assets-list_title {
    color: #e7e7e7; }
  .subs-assets-container .assets-list_content {
    padding-top: 10px; }
  .subs-assets-container .assets-list_content_title {
    color: #a1aab7; }
  .subs-assets-container .assets-list_content_checkboxes {
    padding-top: 5px;
    min-height: 80px; }
    .subs-assets-container .assets-list_content_checkboxes.empty {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0px; }

.dropdown-content.general-settings-dropdown .dropdown-content-wrapper {
  padding-bottom: 39px;
  position: relative; }

.dropdown-content.general-settings-dropdown .dropdown-content-container {
  border: none;
  padding: 21px 21px 20px 25px; }

.dropdown-content.general-settings-dropdown .dropdown-content-container + svg {
  position: absolute;
  bottom: 8px;
  right: 13px;
  width: 52px;
  height: 32px; }

.dropdown-content.general-settings-dropdown .op_general-settings-content {
  display: flex;
  flex-direction: row; }

.dropdown-content.general-settings-dropdown .op_watermark-settings-container {
  width: 105px;
  padding-left: 7px; }

.dropdown-content.general-settings-dropdown .op_timeline-and-media-time-format-settings-container {
  width: 180px;
  padding-left: 7px; }

.general-settings-dropdown_delimiter {
  background-color: #a1aab7;
  width: 1px;
  margin: 3px 17px; }

.dropdown-content.general-settings-dropdown .radio-container_radio_title {
  color: #999fa9; }

.general-settings-dropdown-icon {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center; }

.op_control-bar-shortcuts {
  font-family: Helvetica, Arial, sans-serif;
  padding-left: 3px; }

.op_control-bar-shortcuts_title {
  font-size: 16px;
  font-weight: 300;
  text-align: left !important;
  margin: 0 !important;
  padding-bottom: 7px; }

.op_control-bar-shortcuts ul li button {
  border: 0.5px solid white;
  border-radius: 4px;
  margin-right: 5px;
  padding: 0px 6px;
  text-transform: lowercase; }

.customize-btn {
  border: 0.5px solid blue !important; }

.selected-customize-btn {
  border: 0.5px solid red !important; }

.op_control-bar-shortcuts ul > li > span {
  margin-left: 10px;
  white-space: nowrap; }

.op_control-bar-shortcuts .op_control-bar-shortcuts_content {
  display: flex;
  flex-direction: row; }

.op_control-bar-shortcuts .op_control-bar-shortcuts_content .keyboard-shortcuts-column:first-child {
  width: 200px;
  padding-right: 5px; }

.op_control-bar-shortcuts .op_control-bar-shortcuts_content .keyboard-shortcuts-column:nth-child(2) {
  width: 240px; }

.op_control-bar-shortcuts .op_control-bar-shortcuts_content li.keyboard-shortcuts-buttons-and-description {
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  cursor: default; }

.op_control-bar-shortcuts li.keyboard-shortcuts-buttons-and-description .keyboard-shortcuts-buttons {
  width: 106px;
  min-width: 106px;
  text-align: right; }

.op_control-bar-shortcuts li.keyboard-shortcuts-buttons-and-description .keyboard-shortcuts-description {
  text-align: left; }

.op_control-bar-shortcuts .keyboard-shortcuts-column .change-keyboard-shortcut-options {
  margin-top: 10px; }

.op_control-bar-shortcuts .keyboard-shortcuts-column .change-keyboard-shortcut-options > .radio-container {
  padding-left: 30px; }

.op_control-bar-shortcuts .keyboard-shortcuts-column .change-keyboard-shortcut-options .change-keyboard-shortcut-options_customize {
  cursor: pointer;
  display: flex;
  margin-top: 5px;
  text-align: left;
  font-size: 13px;
  padding-left: 30px;
  color: #1f72f2;
  position: relative; }

.op_control-bar-shortcuts .keyboard-shortcuts-column .change-keyboard-shortcut-options .change-keyboard-shortcut-options_customize:hover {
  text-decoration: underline; }

.shortcut-info {
  background-color: #1b1e24;
  border: 1px solid #ffb209;
  border-radius: 4px;
  bottom: 0;
  color: #ffffff;
  min-height: 34px;
  left: 125px;
  padding: 8px;
  position: absolute;
  width: 310px; }

.info-btn {
  padding: 0 10px; }

.op_media-time-format-settings {
  font-family: Helvetica, Arial, sans-serif; }

.op_media-time-format-settings_title {
  font-size: 16px;
  font-weight: 300;
  text-align: left !important;
  padding-bottom: 7px; }

.op_media-time-format-settings .radio-container_radio {
  align-items: flex-start;
  height: auto;
  padding: 4px 0; }

.op_media-time-format-settings .radio-container_radio .radio-container_radio_custom {
  position: relative;
  top: 2px; }

.op_media-time-format-settings .radio-container_radio .radio-container_radio_title {
  line-height: 17px;
  padding-left: 5px; }

.op_timeline-settings {
  font-family: Helvetica, Arial, sans-serif;
  padding-bottom: 25px; }

.op_timeline-settings_title {
  font-size: 16px;
  font-weight: 300;
  text-align: left !important;
  padding-bottom: 7px; }

.op_timeline-settings .checkbox-container_checkbox {
  align-items: flex-start;
  height: auto;
  padding: 4px 0; }

.op_timeline-settings .checkbox-container_checkbox .checkbox-container_checkbox_custom {
  position: relative;
  top: 2px; }

.op_timeline-settings .checkbox-container_checkbox .radio-container_radio_title {
  line-height: 17px;
  padding-left: 5px; }

.op_watermark-settings {
  font-family: Helvetica, Arial, sans-serif;
  padding-bottom: 40px; }

.op_watermark-settings_title {
  font-size: 16px;
  font-weight: 300;
  text-align: left !important;
  padding-bottom: 7px; }

.diagnostic-box-container {
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
  min-width: 150px;
  min-height: 130px;
  border-radius: 12px;
  background: rgba(57, 59, 63, 0.9);
  flex-direction: column;
  padding: 0 6px; }

.diagnostic-box-container > .diagnostic-box-container_header {
  display: flex;
  flex-direction: row;
  height: 20px;
  align-items: center;
  color: #8e8f8f;
  width: 100%; }

.diagnostic-box-container > .diagnostic-box-container_header > .diagnostic-box-container_header_title {
  display: flex;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  flex: 1;
  font-weight: 300;
  padding-left: 5px; }

.diagnostic-box-container > .diagnostic-box-container_header > .diagnostic-box-container_header_icon {
  display: flex;
  align-items: center;
  cursor: pointer; }

.diagnostic-box-container > .diagnostic-box-container_content {
  padding: 10px 11px 5px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300; }

.diagnostic-box-container > .diagnostic-box-container_content > .diagnostic-box-container_content_record {
  color: #8e8f8f;
  font-size: 11px;
  display: flex;
  width: 100%;
  padding-bottom: 7px; }

.force-staging-overlay_buttons {
  display: flex;
  justify-content: center;
  align-items: center; }

.force-staging-overlay_buttons {
  padding-top: 12px; }

.force-staging-overlay_buttons > .custom-button:first-of-type {
  margin-right: 10px; }

.force-staging-overlay_buttons > .custom-button {
  border-radius: 2px !important;
  font-size: 14px !important;
  padding: 6px 23px !important; }

.force-staging-overlay_buttons_cancel,
.force-staging-overlay_buttons_cancel:hover {
  background: transparent !important;
  color: #2c60ae; }

.force-staging-overlay .playback-setup-overlay_description {
  z-index: 5; }

.restoring-overlay_description {
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 5; }
  .restoring-overlay_description_text:not(:first-of-type) {
    margin-bottom: 10px; }
  .restoring-overlay_description_buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .restoring-overlay_description_buttons-container .button-ui-container {
      box-sizing: border-box !important; }
      .restoring-overlay_description_buttons-container .button-ui-container:not(:first-of-type) {
        margin-left: 10px; }

.playback-setup-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: black; }

.playback-setup-overlay_main-title {
  font-size: 18px;
  top: -3px;
  position: relative;
  padding-bottom: 20px;
  color: #fff; }

.playback-setup-overlay_description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff; }

.conformance-overlay-container {
  background-color: #222A36 !important;
  padding: 30px 50px 40px;
  top: 70px; }
  .conformance-overlay-container .overlay-header {
    background: transparent !important;
    height: 35px !important;
    border-bottom: 2px solid #434C5C !important;
    padding: 0 !important; }
    .conformance-overlay-container .overlay-header .overlay-button-close > svg {
      width: 20px !important;
      height: 20px !important; }
  .conformance-overlay-container .overlay-body {
    background: transparent !important;
    padding: 30px 0 0 !important;
    display: flex;
    flex-direction: column; }
    .conformance-overlay-container .overlay-body .dropdown-ui-container_label {
      padding-bottom: 2px !important;
      color: #8D919A; }
    .conformance-overlay-container .overlay-body .bottom-padding {
      padding-bottom: 20px !important; }
    .conformance-overlay-container .overlay-body_type-dropdown {
      width: 100%;
      display: flex; }
    .conformance-overlay-container .overlay-body_actions {
      width: 100%;
      display: flex;
      justify-content: flex-end; }
      .conformance-overlay-container .overlay-body_actions > .button-ui-container:not(:first-of-type) {
        margin-left: 10px; }

.feature-search-container {
  display: flex;
  width: 100%;
  flex-direction: column; }

.series-search-container {
  width: 100%;
  display: flex;
  flex-direction: column; }

.video-not-found-overlay,
.loader-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.loader-overlay .loader-text,
.video-not-found-overlay .video-not-found-text {
  color: #fff; }

.loader-overlay .loader {
  width: 102px;
  height: 102px; }

.loader-overlay .loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 90px;
  height: 90px;
  margin: 6px;
  border: 9px solid;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; }

.loader-overlay .loader div:nth-child(1) {
  animation-delay: -0.45s; }

.loader-overlay .loader div:nth-child(2) {
  animation-delay: -0.3s; }

.loader-overlay .loader div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0; }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em; }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em; }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em; }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0; }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em; } }

.scroll-control {
  position: absolute;
  height: 100%;
  width: 35px;
  z-index: 10;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; }

.scroll-control.left {
  background-image: -webkit-linear-gradient(to left, rgba(255, 255, 255, 0), #1f72f2 97%);
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #1f72f2 97%);
  left: 0px; }

.scroll-control.right {
  background-image: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0), #1f72f2 97%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #1f72f2 97%);
  right: 0px; }

.scroll-control:hover {
  opacity: 0.85;
  width: 50px; }

.thumbnail-tooltip {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 50px;
  flex-direction: column;
  transition: opacity 0.5s;
  z-index: 1; }

.thumbnail-tooltip.op_thumbnail-tooltip_hidden {
  opacity: 0; }

.thumbnail-tooltip_tail {
  position: relative;
  border-color: rgba(255, 255, 255, 0.67) transparent;
  border-style: solid;
  width: 0;
  display: block; }

.thumbnail-tooltip-container {
  position: relative;
  display: flex; }

.thumbnail-tooltip-container_wrapper {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.67);
  position: relative;
  border-radius: 3px;
  box-sizing: border-box; }

.thumbnail-tooltip-container_wrapper_image {
  flex: 1;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center; }

.thumbnail-tooltip-container_wrapper_image--empty {
  padding: 0; }

.thumbnail-tooltip-container_wrapper_image_thumbnail {
  display: flex;
  align-items: center;
  justify-content: center; }

.thumbnail-tooltip-container_wrapper_time {
  text-align: center;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  color: #4b4b45;
  padding: 4px 0; }

.buttons-group-container {
  display: flex; }
  .buttons-group-container_option {
    box-sizing: border-box;
    min-width: 50px;
    height: 40px;
    background-color: transparent;
    border-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-color: #8D919A;
    padding: 10px 15px;
    cursor: pointer; }
    .buttons-group-container_option.selected {
      border-color: #1F72F2;
      background-color: #0E1115; }
      .buttons-group-container_option.selected + div {
        border-left-color: #1F72F2 !important; }
      .buttons-group-container_option.selected.error {
        border-color: rgba(255, 178, 9, 0.7);
        background-color: #0E1115; }
        .buttons-group-container_option.selected.error + div {
          border-left-color: rgba(255, 178, 9, 0.7) !important; }
    .buttons-group-container_option:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-left-style: solid; }
    .buttons-group-container_option:last-of-type {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-right-style: solid; }

.dropdown-ui-container {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .dropdown-ui-container_label-container {
    display: flex;
    height: 30px; }
    .dropdown-ui-container_label-container_label {
      color: #fff;
      flex: 1;
      display: flex;
      align-items: center;
      height: 100%;
      box-sizing: border-box; }
    .dropdown-ui-container_label-container_error-log {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      box-sizing: border-box; }
  .dropdown-ui-container_dropdown {
    display: flex;
    position: relative;
    box-sizing: border-box;
    height: 40px;
    border-radius: 3px;
    border-color: rgba(129, 148, 181, 0.4);
    border-style: solid;
    cursor: pointer; }
    .dropdown-ui-container_dropdown > .dropdown {
      display: flex;
      align-items: center;
      width: 100%; }
      .dropdown-ui-container_dropdown > .dropdown > .dropdown-area {
        width: 100%;
        height: 100%; }
    .dropdown-ui-container_dropdown.disable-match-width > .dropdown {
      flex: unset; }
      .dropdown-ui-container_dropdown.disable-match-width > .dropdown > .dropdown-area {
        width: unset; }
    .dropdown-ui-container_dropdown.borderless {
      border-color: transparent !important; }
    .dropdown-ui-container_dropdown.fixed-button-width > .dropdown {
      flex: unset; }
    .dropdown-ui-container_dropdown_content {
      border-width: 1px;
      border-style: solid;
      border-color: #1F72F2;
      border-radius: 3px;
      min-height: 40px;
      background-color: #1B1E24;
      color: #fff; }
      .dropdown-ui-container_dropdown_content_list_item {
        padding: 9px 15px 10px 15px;
        box-sizing: border-box;
        height: 40px;
        cursor: pointer;
        color: #fff; }
        .dropdown-ui-container_dropdown_content_list_item.selected {
          background-color: #434C5C; }
        .dropdown-ui-container_dropdown_content_list_item:hover:not(.selected) {
          background-color: rgba(67, 76, 92, 0.3); }
      .dropdown-ui-container_dropdown_content_list.scroll {
        overflow-y: scroll;
        overflow-x: hidden; }
      .dropdown-ui-container_dropdown_content_list.empty {
        display: flex;
        justify-content: center;
        align-items: center; }
      .dropdown-ui-container_dropdown_content_search-field {
        width: 100%;
        padding: 5px;
        box-sizing: border-box; }
        .dropdown-ui-container_dropdown_content_search-field_wrapper {
          position: relative;
          height: 40px;
          width: 100%;
          border-width: 1px;
          border-style: solid;
          border-color: #434C5C;
          border-radius: 4px;
          box-sizing: border-box;
          padding: 9px 10px 10px 42px; }
          .dropdown-ui-container_dropdown_content_search-field_wrapper input {
            width: 100%;
            border: none !important;
            margin: 0 !important;
            background-color: transparent;
            padding: 0;
            color: #fff; }
            .dropdown-ui-container_dropdown_content_search-field_wrapper input::placeholder {
              color: #fff;
              color: #8194B5 !important; }
          .dropdown-ui-container_dropdown_content_search-field_wrapper > svg {
            position: absolute;
            top: 11px;
            left: 13px; }
    .dropdown-ui-container_dropdown_button {
      width: 100%;
      position: relative;
      box-sizing: border-box;
      height: 100%;
      display: flex;
      align-items: center; }
      .dropdown-ui-container_dropdown_button_content {
        color: #fff;
        width: 100%;
        z-index: 5;
        height: 100%;
        display: flex;
        align-items: center; }
        .dropdown-ui-container_dropdown_button_content_text {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding-top: 8.5px; }
        .dropdown-ui-container_dropdown_button_content.disable-match-width {
          padding-right: 30px; }
      .dropdown-ui-container_dropdown_button_icon {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        width: 30px;
        right: 0px;
        position: absolute; }
        .dropdown-ui-container_dropdown_button_icon svg {
          transform: rotate(90deg); }
    .dropdown-ui-container_dropdown_remove {
      position: absolute;
      top: 12px;
      right: 16px;
      display: flex; }
    .dropdown-ui-container_dropdown_search-field {
      color: #fff;
      width: 100%;
      background-color: transparent;
      border: none !important; }

.dropdown-error {
  border-color: rgba(255, 178, 9, 0.7); }

.input-container {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 40px;
  border-radius: 3px;
  border-color: rgba(129, 148, 181, 0.4);
  border-style: solid;
  border-width: 1px;
  padding: 9px 15px 10px 15px;
  background-color: transparent;
  box-shadow: none;
  color: #fff; }
  .input-container:focus {
    border-color: #1F72F2; }

.text-field-container {
  display: flex;
  width: 100%;
  flex-direction: column; }
  .text-field-container_label {
    color: #8D919A;
    height: 30px;
    display: flex;
    align-items: center; }
  .text-field-container_content {
    height: 40px;
    box-sizing: border-box;
    padding-top: 9px;
    padding-bottom: 10px; }
    .text-field-container_content.edit {
      padding-top: 0px;
      padding-bottom: 0px; }
    .text-field-container_content_text, .text-field-container_content_input {
      width: 100%;
      height: 100%;
      color: #fff; }
    .text-field-container_content_input {
      border-radius: 3px;
      border-color: rgba(129, 148, 181, 0.4);
      border-style: solid;
      border-width: 1px;
      padding: 9px 15px 10px 15px;
      background-color: transparent;
      box-shadow: none;
      box-sizing: border-box;
      margin-top: 0 !important; }
      .text-field-container_content_input:focus {
        border-color: #1F72F2; }

.toggle-ui-container {
  display: flex;
  flex-direction: column; }
  .toggle-ui-container_label {
    color: #fff;
    height: 30px;
    display: flex;
    align-items: center; }
  .toggle-ui-container_content {
    display: flex;
    align-items: center; }
    .toggle-ui-container_content_slider {
      position: relative;
      display: flex;
      height: 40px;
      box-sizing: border-box;
      width: 50px;
      cursor: pointer;
      align-items: center; }
      .toggle-ui-container_content_slider_circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: #8D919A;
        background-color: #222934;
        z-index: 10;
        transition: all 450ms;
        transform-origin: -12.5px 0;
        transform: translateX(0); }
        .toggle-ui-container_content_slider_circle.checked {
          transform-origin: 12.5px 0;
          transform: translateX(25px);
          border-color: #1F72F2; }
      .toggle-ui-container_content_slider_bar {
        position: absolute;
        width: 100%;
        left: 0px;
        border-radius: 3px;
        border-style: solid;
        border-color: transparent;
        height: 4px;
        box-sizing: border-box;
        top: 18px;
        background-color: #8D919A; }
        .toggle-ui-container_content_slider_bar.checked {
          background-color: #1F72F2; }
    .toggle-ui-container_content_text {
      color: #fff;
      padding: 0 10px; }

.title-header-container {
  display: flex;
  flex-direction: column;
  flex: 1; }
  .title-header-container_episode {
    color: #A1AAB7;
    margin-bottom: 10px; }
  .title-header-container_title {
    color: #fff;
    margin-bottom: 15px; }
  .title-header-container_version {
    color: #8D919A; }

.asset-select-container {
  display: flex;
  width: 100%;
  height: 62px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(67, 76, 92, 0.3); }
  .asset-select-container_picker {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    justify-content: center;
    padding: 0 10px;
    max-width: 450px;
    box-sizing: border-box; }
    .asset-select-container_picker .dropdown-ui-container .dropdown-area {
      width: 100% !important; }
    .asset-select-container_picker .dropdown-ui-container_label {
      color: #8D919A;
      padding-bottom: 0px; }
    .asset-select-container_picker_arrow {
      border-bottom: 13px solid #1B1E24;
      border-top: 0px solid transparent;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      position: absolute;
      bottom: 0px;
      left: 55px; }

.floating-warning-container {
  cursor: pointer; }
  .floating-warning-container_content {
    width: 100%;
    height: 100%;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    padding: 10px;
    text-align: center; }
    .floating-warning-container_content > p {
      margin: 0 2px !important; }

.collapse-config-container {
  width: 100%;
  display: flex;
  margin-bottom: 2px;
  flex-direction: column; }
  .collapse-config-container_header {
    cursor: pointer;
    color: #8D919A;
    width: 100%;
    display: flex; }
    .collapse-config-container_header.opened {
      padding-bottom: 2px; }
    .collapse-config-container_header_label {
      width: calc(100% - 20px);
      vertical-align: middle;
      text-indent: 5px; }
    .collapse-config-container_header_icon {
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center; }
  .collapse-config-container_body {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-left: 10px;
    box-sizing: border-box; }
    .collapse-config-container_body .configuration-radio-container,
    .collapse-config-container_body .radio-container {
      width: calc(100% - 10px); }

.tmp-container {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center; }

.metadata-audio-container {
  display: flex;
  width: 100%; }

.actions-container {
  position: relative;
  box-sizing: border-box;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #434C5C;
  padding-bottom: 20px;
  display: flex;
  margin-bottom: 50px;
  height: 65px; }
  .actions-container_edit-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 30px;
    bottom: -34px;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: #e31f2e;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12), 0 12px 12px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer; }
    .actions-container_edit-btn:hover {
      background-color: #b00f1c; }
  .actions-container_buttons-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end; }
    .actions-container_buttons-container .button-ui-container:not(:first-of-type) {
      margin-left: 10px; }

.image-record-container_row_column .dropdown-ui-container {
  width: 100%; }
  .image-record-container_row_column .dropdown-ui-container_label-container_label {
    color: #8D919A; }

.image-record-container_row_column .toggle-ui-container_label {
  color: #8D919A; }

.image-record-container_row_label {
  color: #8194B5;
  margin-top: 5px; }

.nonmedia-record-container_row_column .dropdown-ui-container {
  width: 100%; }
  .nonmedia-record-container_row_column .dropdown-ui-container_label-container_label {
    color: #8D919A; }

.nonmedia-record-container_row_column .toggle-ui-container_label {
  color: #8D919A; }

.nonmedia-record-container_row_label {
  color: #8194B5;
  margin-top: 5px; }

.metadata-audio-container_configurations {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .metadata-audio-container_configurations_data-tables > .collapsible-container {
    margin-bottom: 20px; }

.audio-config-container_row {
  margin-bottom: 0 !important; }
  .audio-config-container_row_title {
    color: #8194B5;
    justify-content: flex-end; }
    .audio-config-container_row_title .audio-detail-row_label {
      font-size: 14px; }
  .audio-config-container_row_value {
    color: #FFFFFF;
    text-align: left;
    flex-grow: 2 !important; }
    .audio-config-container_row_value .audio-detail-row_label {
      font-size: 14px; }

.audio-config-container .audio-section-header {
  border-top: 1px solid #1e2026;
  border-bottom: 1px solid #1e2026;
  margin: 20px  0 !important;
  padding: 9px 0; }
  .audio-config-container .audio-section-header .audio-detail-row_label {
    font-size: 16px !important;
    color: #FFFFFF !important; }

.audio-config-container .audio-section-divider {
  border-bottom: 1px solid #1e2026;
  margin: 10px 0 !important; }

.audio-config-container .audio-detail-row {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap; }
  .audio-config-container .audio-detail-row div {
    padding-left: 10px;
    flex: 1 auto; }
  .audio-config-container .audio-detail-row div:nth-of-type(1) {
    order: 1;
    float: right; }
  .audio-config-container .audio-detail-row div:nth-of-type(2) {
    order: 2;
    float: left; }

.audio-details-container_row_column .dropdown-ui-container {
  width: 100%; }
  .audio-details-container_row_column .dropdown-ui-container_label-container_label {
    color: #8D919A; }

.audio-details-container_row_column .toggle-ui-container_label {
  color: #8D919A; }

.audio-details-container_row_label {
  color: #8194B5;
  margin-top: 5px; }

.metadata-audio-container_mapping {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative; }

.metadata-audio-container_mapping_title {
  border-bottom: 1px solid #434C5C;
  display: flex;
  height: 35px;
  align-items: center;
  width: 100%; }

.metadata-audio-container_mapping_title_text {
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  color: #fff;
  flex: 2; }

.metadata-audio-container_mapping_title_icon_close {
  color: #fff;
  font-size: 38px;
  cursor: pointer; }

.metadata-audio-container_mapping_channel-configs {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  margin-top: 15px; }

.metadata-audio-container_mapping_channel-configs .config-button-container {
  margin-right: 10px; }

.metadata-audio-container_mapping_channel-configs_empty {
  width: 100;
  font-style: italic;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  color: #fff; }

.metadata-audio-container_mapping_channels {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px; }

.metadata-audio-container_mapping_channels_title-cell {
  color: #7a7d87;
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif; }

.metadata-audio-container_mapping_channels_list {
  display: flex;
  flex-direction: column;
  width: 100%; }

.metadata-audio-container_mapping_channels_wrapper {
  /* Remove comment on 'height' rule if scroll content is required */
  /* height: 320px; */
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 9px; }

.metadata-audio-container_mapping_channels_wrapper::-webkit-scrollbar {
  width: 12px; }

.metadata-audio-container_mapping_channels_wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px; }

.metadata-audio-container_mapping_channels_wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: inset 0 0 6px rgba(0, 0, 0, 0.5); }

.metadata-audio-container_mapping_channels_list_empty {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif; }

.metadata-audio-container_mapping_channels_actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px; }

.metadata-audio-container_mapping_channels_actions .cancel-button,
.metadata-audio-container_mapping_channels_actions .save-button {
  border-radius: 3px;
  font-size: 14px;
  padding: 6px 19px; }

.metadata-audio-container_mapping_channels_actions .cancel-button {
  background: transparent;
  color: #1f72f2; }

.metadata-audio-container_mapping_channels_actions .custom-button:not(:last-of-type) {
  margin-right: 10px; }

.metadata-audio-container_mapping_channels_actions .custom-button.cancel-button:hover {
  background: transparent !important;
  color: #2c60ae; }

.metadata-audio-container_mapping_processing-mask {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  color: #fff;
  font-size: 16px;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; }

.channel-row-container {
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  border: 1.5px solid transparent;
  border-bottom: 1px solid #434C5C; }

.channel-row-container.auto-height {
  height: auto !important; }

.channel-row-container:not(:last-of-type) {
  margin-bottom: 6px; }

.channel-row-container .channel-row-container_channel {
  color: #fff; }

.channel-row-container .channel-row-container_assignment,
.channel-row-container .channel-row-container_stream-in-file {
  color: #014fc6;
  font-weight: bold; }

.channel-row-container .channel-row-container_assignment.editable {
  cursor: pointer; }

.channel-row-container_assignment_multiple-channels {
  display: flex;
  flex-direction: column; }

.channel-row-container_assignment_multiple-channels .custom-select-dropdown-container:not(:last-of-type) {
  padding-bottom: 5px; }

.config-button-container {
  width: 50px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  border: 1px solid #1f72f2;
  background: #1f72f2;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif; }

.config-button-container:hover {
  background: #014fc6;
  border: 1px solid #014fc6; }

.config-button-container .config-button-container_text,
.config-button-container .config-button-container_plus {
  height: 100%;
  display: flex;
  align-items: center; }

.config-button-container .config-button-container_text {
  font-size: 13px;
  justify-content: flex-start;
  flex: 2; }

.config-button-container .config-button-container_plus {
  font-size: 16px;
  justify-content: flex-end;
  color: #cecbcb; }

.config-group-container {
  border-width: 1.5px;
  border-style: solid;
  border-color: #014fc6;
  box-sizing: border-box;
  margin-bottom: 6px;
  border-radius: 5px;
  position: relative; }

.incomplete {
  border-color: red !important; }

.config-group-container,
.config-group-container_channels {
  width: 100%;
  display: flex;
  flex-direction: column; }

.config-group-container_channel-configs {
  width: 100%;
  margin-top: -5px; }

.config-group-container_channel-configs_row {
  height: 40px; }

.config-group-container_channel-configs_row .config-row-container_x3,
.config-group-container_channel-configs_row .config-row-container_x5 {
  justify-content: flex-end; }

.config-group-container_channel-configs_row .channel-conf,
.config-group-container_channel-configs_row .control-label,
.config-group-container_channel-configs_row .channel-type,
.config-group-container_channel-configs_row .channel-language {
  font-size: 15px;
  font-family: Helvetica, Arial, sans-serif; }

.config-group-container_channel-configs_row .control-label {
  padding-right: 7px;
  color: #8194b5; }

.config-group-container_channel-configs_row .channel-conf {
  color: #fff; }

.config-group-container_channel-configs_row .channel-type,
.config-group-container_channel-configs_row .channel-language {
  color: #014fc6;
  cursor: pointer; }

.config-group-container_channels .config-row-container {
  background: transparent;
  border: none; }

.config-group-container_close-icon {
  position: absolute;
  top: 3px;
  right: 90px;
  color: #8194b5;
  font-size: 30px;
  cursor: pointer; }

.grouped-item {
  margin-top: 20px; }

.config-row-container {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center; }

.config-row-container .cell {
  height: 100%;
  flex: 1;
  align-items: center;
  display: flex; }

.config-row-container_x1 {
  width: 50px;
  /* background: red; */
  flex: unset !important; }

.config-row-container_x2 {
  width: 60px;
  /* background: green; */
  flex: unset !important; }

/* .config-row-container_x3 {
  background: orange;

} */
/* .config-row-container_x4 {
  background: teal;
} */
/* .config-row-container_x5 {
  background: skyblue;
} */
/* .config-row-container_x6 {
  background: salmon;
} */
.collapsible-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute; }
  .collapsible-container.visible {
    visibility: visible;
    opacity: 1;
    position: relative; }
  .collapsible-container_header {
    display: flex;
    background-color: #0E1115;
    height: 50px;
    box-sizing: border-box;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 16px 30px 13px 30px;
    cursor: pointer; }
    .collapsible-container_header.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
    .collapsible-container_header_text {
      color: #fff;
      flex: 1; }
    .collapsible-container_header_icon {
      display: flex;
      height: 100%;
      align-items: center; }
  .collapsible-container_content {
    background-color: rgba(14, 17, 21, 0.5);
    box-sizing: border-box;
    padding: 15px 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px; }
    .collapsible-container_content.hidden {
      display: none; }

.op_comments-container {
  position: relative;
  display: flex;
  box-sizing: border-box;
  line-height: normal;
  width: 100%;
  height: calc(100% - 115px);
  max-height: 205px;
  flex-direction: column;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px; }

.op_comments-container * {
  box-sizing: border-box; }

.op_comments-container .comments-container_top-gradient-bar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 10px;
  background: linear-gradient(to bottom, #3a404a, rgba(58, 64, 74, 0));
  z-index: 2; }

.op_comments-container .comments-container_bottom-gradient-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 10px;
  background: linear-gradient(to top, #3a404a, rgba(58, 64, 74, 0));
  z-index: 2; }

.op_comments-container .op_comments-container_comments-wrapper {
  height: 100%;
  padding: 5px 18px;
  overflow-y: scroll; }

.op_comments-container .op_comments-container_comment {
  position: relative;
  margin-bottom: 5px;
  min-width: 550px; }

.op_comments-container .op_comments-container_comment:last-child {
  margin-bottom: 0; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header {
  display: flex;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  background-color: #959595;
  font-family: Helvetica, Arial, sans-serif;
  color: #ffffff;
  padding: 5px 10px 5px 10px; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_info-container {
  display: flex;
  flex-direction: row; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_action-icons {
  display: flex;
  flex-direction: row; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_name-and-post-time {
  display: flex;
  flex-direction: column;
  margin-right: 23px; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_author {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin-bottom: 4px;
  margin-top: 2px;
  font-size: 14px;
  width: 187px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_post-time {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 8px; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_avatar {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  background: 100%/100%;
  margin-right: 10px; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_in-time-and-out-time {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center; }

.op_comments-container .comments-container_comment_header_in-time,
.op_comments-container .comments-container_comment_header_out-time {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 14px; }

.op_comments-container .comments-container_comment_header_time-delimiter {
  padding: 0 15px;
  font-size: 14px; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_in-time-and-out-time .comments-container_comment_header_play-btn {
  margin-left: 8px;
  cursor: pointer; }

.op_comments-container .op_comments-container_comment .comments-container_comment_header_in-time-and-out-time .comments-container_comment_header_play-btn svg {
  padding: 7px;
  overflow: visible;
  box-sizing: initial;
  display: block; }

.op_comments-container .op_comments-container_comment .comments-container_comment_current-user-comment-tail {
  position: absolute;
  top: 0;
  left: -16px;
  height: 0;
  width: 0;
  border-width: 13px 0px 0px 16px;
  border-color: #e7e6e6 transparent;
  border-style: solid; }

.op_comments-container .op_comments-container_comment .comments-container_comment_another-user-comment-tail {
  position: absolute;
  bottom: 17px;
  right: -13px;
  height: 0;
  width: 0;
  border-width: 0 13px 16px 0;
  border-color: #e7e6e6 transparent;
  border-style: solid; }

.op_comments-container .comments-container_comment_header_edit-icon {
  position: relative;
  top: -2px;
  height: 17px;
  padding: 5px;
  cursor: pointer; }

.op_comments-container .comments-container_comment_header_delete-icon {
  height: 16px;
  padding: 5px;
  cursor: pointer; }

.op_comments-container .op_comments-container_comment .comments-container_comment_body {
  position: relative;
  border-radius: 0 0 10px 10px;
  background-color: #e7e6e6;
  padding: 7px 12px;
  white-space: pre-wrap;
  word-wrap: break-word; }

.op_comments-container .op_comments-container_comment .comments-container_comment_body .comments-container_comment_text {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #534c4c;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply-regular {
  position: relative;
  border-radius: 10px;
  background-color: #d8d8d8;
  margin-bottom: 5px;
  padding: 5px 10px 10px 10px; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply-regular:first-child {
  margin-top: 5px; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply-regular .replies-container_reply_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Helvetica, Arial, sans-serif;
  min-height: 18px; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply-regular .replies-container_reply_header_action-icons {
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply-regular .replies-container_reply_header_edit-icon {
  height: 14px;
  padding: 2px;
  margin-right: 2px;
  cursor: pointer; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply-regular .replies-container_reply_header_delete-icon {
  position: relative;
  top: 2px;
  height: 14px;
  padding: 2px;
  margin-right: 3px;
  cursor: pointer; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply-regular .replies-container_reply_header_post-time {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 8px;
  color: #6e6d6d; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply-regular .replies-container_reply_body {
  font-family: Helvetica, Arial, sans-serif;
  color: #534c4c;
  padding: 2px 0 5px 0;
  margin: 0;
  font-weight: 400; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply_editing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Helvetica, Arial, sans-serif;
  margin-bottom: 5px; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply_editing .replies-container_reply_body_action-icons {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 6px;
  padding-right: 3px; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply_editing .replies-container_reply_body_action-icons .replies-container_reply_body_edit-icon {
  height: 24px;
  padding: 2px 4px 4px 4px;
  cursor: pointer; }

.op_comments-container .op_comments-container_comment .replies-container_reply .replies-container_reply_editing .replies-container_reply_body_action-icons .replies-container_reply_body_delete-icon {
  position: relative;
  top: 2px;
  height: 22px;
  padding: 2px 4px 4px 4px;
  cursor: pointer; }

.op_comments-container .op_comments-container_comment .comments-container_comment_footer {
  display: flex;
  justify-content: flex-end; }

.op_comments-container .op_comments-container_comment .comments-container_comment_footer_add-reply-btn,
.op_comments-container .op_comments-container_comment .comments-container_comment_footer_cancel-reply-btn,
.op_comments-container .op_comments-container_comment .comments-container_comment_footer_save-reply-btn {
  outline: none;
  background-color: transparent;
  border: none;
  padding: 2px 7px;
  color: #1f72f2;
  cursor: pointer;
  font-weight: 600;
  font-size: 12px; }

.op_comments-container textarea.comments-container_comment_text_editing,
.op_comments-container textarea.replies-container_reply_body_editing,
.op_comments-container textarea.replies-container_comment_add-reply {
  width: 100%;
  resize: none;
  min-height: 63px;
  background-color: #d8d8d8;
  border: 1px solid #979797;
  border-radius: 2px;
  -webkit-box-shadow: inset 0px 0px 5px -1px #000000;
  -moz-box-shadow: inset 0px 0px 5px -1px #000000;
  box-shadow: inset 0px 0px 5px -1px #000000;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #534c4c;
  margin: 0;
  padding: 4px 4px; }

.op_comments-container textarea.comments-container_comment_text_editing,
.op_comments-container textarea.replies-container_reply_body_editing {
  margin-left: 6px; }

.op_comments-container textarea.comments-container_comment_text_editing:focus,
.op_comments-container textarea.replies-container_reply_body_editing:focus,
.op_comments-container textarea.replies-container_comment_add-reply:focus {
  border: 1px solid #438cff; }

.op_add-comment-container {
  font-family: Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  background-color: #1b1e24;
  position: relative;
  z-index: 2; }

.op_add-comment-container * {
  box-sizing: border-box; }

.op_add-comment-container .add-comment-container_action-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 0; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .add-comment-container_action-btn {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: 11px;
  font-weight: 300; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .add-comment-container_action-btn.time-in-btn,
.op_add-comment-container .add-comment-container_action-btn-wrapper .add-comment-container_action-btn.time-out-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  border-radius: 10px;
  background-color: #0ab3a0;
  min-height: 22px;
  margin-right: 7px;
  cursor: pointer;
  font-size: 12px; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .add-comment-container_action-btn.time-out-btn {
  margin-left: 20px; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .add-comment-container_action-btn.time-out-btn[disabled] {
  background-color: #77c3bb;
  cursor: default; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .time-picker > div:last-child:not(.arrow-buttons) {
  display: none !important; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .add-comment-container_action-btn.add-comment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 136px;
  border-radius: 10px;
  background-color: #1f72f2;
  min-height: 22px;
  cursor: pointer;
  font-size: 12px; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .add-comment-container_action-btn.add-comment-btn[disabled] {
  background-color: #5f97ef;
  cursor: default; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .add-comment-container_action-btn_text {
  margin-right: 10px; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .time-picker {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  padding: 6px 7px 6px 9px;
  background-color: #24262c;
  border: 1px solid #4a4d55;
  border-radius: 2px;
  width: 114px;
  color: #ffffff; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .time-picker.time-picker-container {
  border-color: transparent; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .time-picker input.input-number {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  margin: 0; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .time-picker .arrow-buttons .blue-button:first-child {
  top: -3px;
  right: -1px; }

.op_add-comment-container .add-comment-container_action-btn-wrapper .time-picker .arrow-buttons .blue-button:last-child {
  bottom: 1px;
  right: -1px; }

.op_add-comment-container textarea.add-comment-container_comment-text {
  display: block;
  width: 100%;
  color: #ffffff;
  border: 1px solid #4a4d55;
  border-radius: 2px;
  background-color: #24262c;
  resize: none;
  min-height: 63px;
  margin: 0;
  padding: 4px 4px; }

.op_add-comment-container textarea.add-comment-container_comment-text:focus {
  border: 1px solid #438cff; }

.comments-container_comment_header_thumbnail-main-container {
  transform: translate(135px, 43px);
  padding: 0; }

.comments-container_comment_header_thumbnail-main-container .comments-container_comment_header_thumbnail-sub-container {
  border-radius: 2px;
  border: 1px solid #959595;
  background-color: #ffffff; }

.comments-container_comment_header_thumbnail-main-container .comments-container_comment_header_thumbnail-tail-top {
  position: absolute;
  left: -18px;
  top: 41px;
  border-width: 0px 15px 11px 15px;
  border-style: solid;
  border-color: white transparent;
  width: 0;
  transform: rotate(-90deg);
  z-index: 3; }

.comments-container_comment_header_thumbnail-main-container .comments-container_comment_header_thumbnail-tail-bottom {
  position: absolute;
  left: -19px;
  top: 41px;
  border-width: 0px 15px 11px 15px;
  border-style: solid;
  border-color: #959595 transparent;
  width: 0;
  transform: rotate(-90deg);
  z-index: 2; }

.comments-container_comment_header_thumbnail-main-container .thumbnail-tooltip-container_wrapper_image {
  padding: 2px; }

.guidelines-container {
  display: flex;
  width: 100%; }

.metadata-history-container {
  width: 100%;
  display: flex;
  flex-direction: column; }

.metadata-history-container_version {
  width: 100%;
  display: flex;
  padding: 8px 5px; }

.markups-container {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  min-height: 350px; }
  .markups-container_actions-row {
    display: flex;
    margin-top: 15px; }
    .markups-container_actions-row_markups-group {
      width: 200px; }
    .markups-container_actions-row_options {
      display: flex;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-left: 10px;
      flex: 1; }
      .markups-container_actions-row_options_option {
        max-width: 120px; }
        .markups-container_actions-row_options_option .dropdown-ui-container {
          width: auto;
          height: 40px;
          box-sizing: border-box; }
          .markups-container_actions-row_options_option .dropdown-ui-container_dropdown.disable-match-width .dropdown-area {
            width: 100% !important; }
          .markups-container_actions-row_options_option .dropdown-ui-container_label-container_label {
            padding-bottom: 0 !important;
            height: 17.6px !important;
            color: #8D919A; }
          .markups-container_actions-row_options_option .dropdown-ui-container_dropdown {
            height: 22.4px !important; }
            .markups-container_actions-row_options_option .dropdown-ui-container_dropdown_button_content_text {
              padding-top: 2px !important; }
      .markups-container_actions-row_options_filters {
        display: flex; }
        .markups-container_actions-row_options_filters > div:first-child {
          margin-right: 25px; }
  .markups-container_timecode-row {
    display: flex;
    padding: 15px 0;
    justify-content: space-between; }
    .markups-container_timecode-row_time-controls {
      display: flex;
      align-items: center; }
    .markups-container_timecode-row .button-ui-container:not(:first-of-type) {
      margin-left: 10px; }

.markup-container_download-edl-dropdown {
  transform: translate(-28px, 180px);
  padding-top: 20px !important; }

.markup-container_download-edl-dropdown .markup-container_download-edl-dropdown_option-items-wrapper {
  background-color: #1b1e24;
  border: 2px solid #1f72f2;
  border-radius: 5px;
  padding: 10px 0;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 300;
  width: 141px; }

.markup-container_download-edl-dropdown .markup-container_download-edl-dropdown_option-items-wrapper .markup-container_download-edl-dropdown_header {
  font-size: 15px;
  padding: 2px 10px 10px 10px; }

.markup-container_download-edl-dropdown .markup-container_download-edl-dropdown_option-items-wrapper .markup-container_download-edl-dropdown_option-item {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #1f72f2;
  padding: 0 21px;
  height: 33px;
  cursor: pointer; }

.markup-container_download-edl-dropdown .markup-container_download-edl-dropdown_option-items-wrapper .markup-container_download-edl-dropdown_option-item svg {
  margin-right: 21px; }

.markup-container_download-edl-dropdown .markup-container_download-edl-dropdown_option-items-wrapper .markup-container_download-edl-dropdown_option-item:hover {
  background-color: #2e3540; }

.markup-container_download-edl-dropdown .markup-container_download-edl-dropdown_tail-top {
  position: absolute;
  right: 11px;
  top: 11px;
  border-width: 0px 9px 13px 9px;
  border-style: solid;
  border-color: #1b1e24 transparent;
  width: 0;
  z-index: 3; }

.markup-container_download-edl-dropdown .markup-container_download-edl-dropdown_tail-bottom {
  position: absolute;
  right: 9px;
  top: 8px;
  border-width: 0px 11px 14px 11px;
  border-style: solid;
  border-color: #1f72f2 transparent;
  width: 0;
  z-index: 2; }

.events-table-container {
  display: flex;
  width: 100%;
  flex-direction: column; }
  .events-table-container.remote-content .event-row_actions {
    max-width: 55px; }
  .events-table-container.remote-content .header-row_action-cell {
    max-width: 55px; }
  .events-table-container_content {
    min-height: 235px;
    height: 100%; }
    .events-table-container_content.empty {
      display: flex;
      justify-content: center;
      align-items: center; }

.table-row-container {
  display: flex;
  width: 100%; }
  .table-row-container_cell {
    flex: 1;
    padding: 10px 0;
    display: flex;
    align-items: center; }

.header-row {
  box-sizing: border-box;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #434C5C; }
  .header-row .table-row-container_cell {
    padding: 0 0 10px 0; }

.event-row {
  box-sizing: border-box;
  border-bottom: 1px solid rgba(67, 76, 92, 0.3); }
  .event-row.error {
    border-bottom-color: rgba(255, 178, 9, 0.7); }
  .event-row .min-width-cell {
    min-width: 0; }
  .event-row_typeCategoryNotesCell {
    overflow: hidden; }
    .event-row_typeCategoryNotesCell.not-edit .dropdown-ui-container.disabled, .event-row_typeCategoryNotesCell.not-edit .player-time-picker-container_fields > input.dropdown-ui-container:disabled {
      opacity: 1 !important;
      pointer-events: all;
      cursor: pointer !important; }
    .event-row_typeCategoryNotesCell.not-edit .event-row_typeCategoryNotesCell_default-type {
      opacity: 1 !important;
      pointer-events: all;
      cursor: pointer !important; }
    .event-row_typeCategoryNotesCell_notes-text-field {
      cursor: pointer;
      height: 40px;
      box-sizing: border-box;
      padding-top: 12px; }
    .event-row_typeCategoryNotesCell_default-type {
      color: #fff; }
      .event-row_typeCategoryNotesCell_default-type.new-default {
        color: #1F72F2; }
      .event-row_typeCategoryNotesCell_default-type.clickable {
        color: #0AB3A0;
        text-decoration: underline; }
  .event-row_timepicker {
    color: #fff;
    font-family: 'Circular Std Book';
    cursor: pointer;
    display: flex; }
    .event-row_timepicker.clickable {
      color: #0AB3A0; }
      .event-row_timepicker.clickable .player-time-picker-container_fields {
        border-bottom: 1px solid #0AB3A0; }
        .event-row_timepicker.clickable .player-time-picker-container_fields input {
          color: #0AB3A0;
          opacity: 1 !important;
          pointer-events: all;
          cursor: pointer !important; }
    .event-row_timepicker.placeholder {
      color: #1F72F2; }
      .event-row_timepicker.placeholder .player-time-picker-container input {
        color: #1F72F2; }
    .event-row_timepicker_add-button {
      height: 40px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px; }
      .event-row_timepicker_add-button_text {
        color: #fff; }
    .event-row_timepicker .player-time-picker-container {
      height: 40px;
      box-sizing: border-box;
      padding-top: 9px;
      padding-right: 0;
      padding-bottom: 10px;
      padding-left: 0;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      border-radius: 3px; }
      .event-row_timepicker .player-time-picker-container.focused {
        border-color: #1F72F2 !important;
        padding-left: 10px !important; }
  .event-row_actions {
    justify-content: flex-end; }
    .event-row_actions_icon {
      cursor: pointer; }
      .event-row_actions_icon:not(:first-of-type) {
        margin-left: 15px; }

.filter-drop-down {
  font-family: Arial, Helvetica, sans-serif;
  width: 600px;
  border: 1px solid #1f72f2;
  border-radius: 4px;
  transform: translate(-450px); }
  .filter-drop-down .time-input {
    width: 50%;
    float: left; }
    .filter-drop-down .time-input .time-picker {
      border: 1px solid #a1aab7;
      width: 70%;
      padding: 5px 10px;
      background-color: #292B30;
      margin-top: 5px; }
  .filter-drop-down p {
    font-size: 17px;
    margin: 20px 0 15px 0; }
  .filter-drop-down label, .filter-drop-down span {
    font-size: 16px; }
  .filter-drop-down span, .filter-drop-down .time-input label {
    color: #a1aab7; }
  .filter-drop-down .filter-time-pickers {
    width: 100%;
    padding: 10px 20px; }
    .filter-drop-down .filter-time-pickers .time-picker .input-number {
      margin: 0; }
  .filter-drop-down .filter-checkbox {
    width: 100%;
    padding: 10px 20px; }
    .filter-drop-down .filter-checkbox .filter-types {
      float: left;
      padding-bottom: 20px;
      width: 50%; }
      .filter-drop-down .filter-checkbox .filter-types div {
        display: flex; }
      .filter-drop-down .filter-checkbox .filter-types span {
        color: #FFFFFF;
        margin-left: 10px;
        line-height: 25px; }
  .filter-drop-down .filter-category {
    float: left;
    padding-bottom: 20px;
    width: 50%; }
    .filter-drop-down .filter-category div {
      display: flex; }
    .filter-drop-down .filter-category span {
      color: #FFFFFF;
      margin-left: 10px;
      line-height: 25px; }

.markup-timecode-player-icon {
  background-color: transparent !important;
  margin-top: 2px;
  width: 10px;
  display: flex;
  cursor: pointer;
  justify-content: center; }
  .markup-timecode-player-icon svg {
    position: relative;
    top: 7px; }

.instructions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px; }
  .instructions-container .dropdown {
    cursor: pointer; }
  .instructions-container_payload {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
    .instructions-container_payload_arrow {
      display: flex;
      width: 0px;
      border-bottom-width: 12.5px;
      border-left-width: 12.5px;
      border-right-width: 12.5px;
      border-style: solid;
      border-bottom-color: #FFB209;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top: none;
      position: relative;
      left: 30px; }
      .instructions-container_payload_arrow::before {
        position: absolute;
        bottom: 0px;
        content: ' ';
        display: flex;
        width: 0px;
        border-bottom-width: 11.5px;
        border-left-width: 11.5px;
        border-right-width: 11.5px;
        border-style: solid;
        border-bottom-color: #434C5C;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top: none;
        left: -11.5px;
        top: 2px; }
    .instructions-container_payload_content {
      display: flex;
      flex-direction: column;
      background-color: #434C5C;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid #FFB209;
      box-shadow: 2px -2px 4px 0 rgba(0, 0, 0, 0.5);
      padding: 25px 30px 15px; }
      .instructions-container_payload_content_buttons {
        display: flex;
        padding-top: 15px; }
        .instructions-container_payload_content_buttons_time {
          width: 50%;
          display: flex;
          flex-direction: column;
          box-sizing: content-box !important; }
          .instructions-container_payload_content_buttons_time_button {
            color: white;
            width: 70px;
            padding: 5px;
            border-radius: 3px;
            border-width: 3px;
            border-color: #8D919A;
            border-style: solid;
            text-align: center;
            margin-top: 10px;
            box-sizing: border-box; }

.metadata-container {
  padding: 15px 0 10px; }
  .metadata-container .tabs-component_content {
    padding: 15px 0 10px; }

.regroup-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 50px;
  right: 10px; }
  .regroup-button p {
    color: #1F72F2;
    font-family: "Circular Std Bold";
    font-size: 14px;
    font-weight: bold;
    line-height: 18px; }

.details-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative; }
  .details-container_error {
    color: red;
    margin-bottom: 15px; }
  .details-container > .collapsible-container {
    margin-bottom: 20px; }

.title-info-container_version-confromance-row_column .dropdown-ui-container {
  width: 100%; }
  .title-info-container_version-confromance-row_column .dropdown-ui-container_label-container_label {
    color: #8D919A; }

.asset-info-container .dropdown-ui-container {
  width: 100%; }
  .asset-info-container .dropdown-ui-container_label-container_label {
    color: #8D919A; }

.asset-info-container .toggle-ui-container_label {
  color: #8D919A; }

.asset-info-container_frame-rate-title {
  color: #8194B5;
  padding: 0 10px;
  box-sizing: border-box; }

.references-container {
  margin-top: 15px; }
  .references-container_header {
    box-sizing: border-box;
    padding: 0 10px; }
    .references-container_header_title {
      color: #8194B5;
      margin-top: 15px; }
  .references-container_body {
    flex-direction: column; }
    .references-container_body .toggle-ui-container {
      box-sizing: border-box;
      padding: 0 10px; }
  .references-container_footer {
    box-sizing: border-box;
    padding: 15px 0;
    border-top: 1px solid rgba(161, 170, 183, 0.5); }
    .references-container_footer_text {
      color: #1F72F2;
      cursor: pointer; }
  .references-container_error {
    border-top: 1px solid rgba(255, 178, 9, 0.7); }

.reference-divider {
  width: 100%;
  border: 0.5px solid rgba(161, 170, 183, 0.5); }

.distributors-container {
  margin-top: 15px; }
  .distributors-container_header {
    box-sizing: border-box;
    padding: 0 10px; }
    .distributors-container_header_title {
      color: #8194B5; }
  .distributors-container_body {
    flex-direction: column; }
    .distributors-container_body .toggle-ui-container {
      box-sizing: border-box;
      padding: 0 10px; }
    .distributors-container_body_reference_distributor {
      flex: 2 !important; }
  .distributors-container_footer {
    box-sizing: border-box;
    padding: 15px 0;
    border-top: 1px solid rgba(161, 170, 183, 0.5); }
    .distributors-container_footer_text {
      color: #1F72F2;
      cursor: pointer; }

.distributor-divider {
  width: 100%;
  border-top: 1px solid rgba(161, 170, 183, 0.5); }

.grid-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .grid-container_row {
    width: 100%;
    display: flex;
    margin-bottom: 15px; }
    .grid-container_row_column {
      display: flex;
      flex: 1;
      padding: 0 10px; }

.metadata-program-timings-container {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .metadata-program-timings-container div {
    margin-bottom: 5px; }
  .metadata-program-timings-container .metadata-program-timings-container-header {
    display: flex;
    flex-flow: row wrap;
    color: #8D919A;
    font-size: 11px;
    font-weight: 300; }
    .metadata-program-timings-container .metadata-program-timings-container-header div {
      padding-left: 30px;
      flex: 1 auto; }
    .metadata-program-timings-container .metadata-program-timings-container-header div:nth-of-type(1) {
      order: 1; }
    .metadata-program-timings-container .metadata-program-timings-container-header div:nth-of-type(2) {
      order: 2; }
    .metadata-program-timings-container .metadata-program-timings-container-header div:nth-of-type(3) {
      order: 3; }

.program-timing-play {
  background: transparent !important;
  color: #ffffff !important;
  display: flex;
  font-size: 14px;
  font-weight: 300; }
  .program-timing-play div {
    background: transparent !important;
    margin-left: 5px !important; }
  .program-timing-play svg {
    margin-top: 7px; }
  .program-timing-play .markup-timecode_timecode {
    line-height: 30px; }

.program-timing-thumbnail {
  transform: translate(120px, 45px); }

.row-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 5px; }
  .row-container .row-container-header {
    display: flex;
    background-color: #0E1115;
    height: 50px;
    box-sizing: border-box;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 16px 30px 13px 30px;
    cursor: pointer;
    flex-flow: row wrap; }
    .row-container .row-container-header div {
      padding-left: 10px;
      flex: 1 auto; }
    .row-container .row-container-header div:nth-of-type(1) {
      order: 1;
      flex-grow: 2;
      max-width: 40%; }
    .row-container .row-container-header div:nth-of-type(2) {
      order: 2; }
    .row-container .row-container-header div:nth-of-type(3) {
      order: 3; }
    .row-container .row-container-header_text {
      color: #fff;
      flex: 1; }

.metadata-subtitles-container {
  display: flex;
  flex-direction: column;
  width: 100%; }

.main-tabs-container {
  display: flex;
  flex-direction: column;
  position: relative; }

.tabs-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 736px;
  padding-bottom: 20px; }
  .tabs-component_tabs {
    display: flex;
    flex-direction: row; }
    .tabs-component_tabs_tab-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 33px;
      box-sizing: border-box;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: #434C5C;
      color: #8194B5;
      padding: 10px 8px;
      min-width: 110px;
      cursor: pointer; }
      .tabs-component_tabs_tab-content.active {
        color: #1F72F2;
        border-bottom-color: #1F72F2; }
      .tabs-component_tabs_tab-content.disabled, .player-time-picker-container_fields > input.tabs-component_tabs_tab-content:disabled {
        pointer-events: none;
        color: rgba(129, 148, 181, 0.3); }
      .tabs-component_tabs_tab-content.empty {
        flex: 2; }
        .tabs-component_tabs_tab-content.empty:hover {
          cursor: default;
          border-bottom-color: #434C5C; }
    .tabs-component_tabs_grid .buttons-group-container_option.selected {
      background: transparent !important; }
    .tabs-component_tabs_grid_tab-content {
      color: #8194B5; }
      .tabs-component_tabs_grid_tab-content.active {
        color: #1F72F2;
        border-bottom-color: #1F72F2; }
  .tabs-component_content {
    min-height: 200px;
    height: 100%; }
    .tabs-component_content .ReactVirtualized__Table .ReactVirtualized__Table__headerRow {
      background: #2e3541; }
    .tabs-component_content .ReactVirtualized__Table .ReactVirtualized__Table__headerRow .ReactVirtualized__Table__headerColumn {
      color: #a1aab7;
      border: none;
      background: transparent; }
    .tabs-component_content .hide-last-header .ReactVirtualized__Table .ReactVirtualized__Table__headerRow .ReactVirtualized__Table__headerColumn:last-of-type {
      color: transparent;
      text-align: center !important; }
    .tabs-component_content .ReactVirtualized__Table .ReactVirtualized__Table__rowColumn {
      border: none !important;
      background: transparent !important;
      text-align: left !important;
      color: #fff; }
    .tabs-component_content .ReactVirtualized__Table .ReactVirtualized__Table__row {
      border-bottom: 1px solid #979797;
      background-color: #0e1115; }
    .tabs-component_content .column-options {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: transparent !important; }
    .tabs-component_content .column-options_icon {
      background: transparent !important;
      padding: 0 8px;
      cursor: pointer; }
    .tabs-component_content div.ReactVirtualized__Table__headerColumn {
      height: fit-content; }
    .tabs-component_content .time-picker .input-number {
      margin: 0;
      box-sizing: unset; }
    .tabs-component_content .scroll-control.right,
    .tabs-component_content .scroll-control.left {
      display: none; }
  .tabs-component_actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 7px 0; }
    .tabs-component_actions_count {
      color: #fff;
      min-width: 180px;
      text-align: center; }
    .tabs-component_actions_icons {
      display: flex;
      flex-direction: row;
      min-width: 60px;
      justify-content: space-around;
      align-items: center; }
      .tabs-component_actions_icons_icon {
        cursor: pointer; }

.thumbnail-tooltip-container {
  position: relative;
  display: flex; }

.thumbnail-tooltip-container_wrapper {
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.67);
  position: relative;
  border-radius: 3px;
  box-sizing: border-box; }

.thumbnail-tooltip-container_wrapper_image {
  flex: 1;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center; }

.thumbnail-tooltip-container_wrapper_image--empty {
  padding: 0; }

.thumbnail-tooltip-container_wrapper_image_thumbnail {
  display: flex;
  align-items: center;
  justify-content: center; }

.versions-container {
  width: 100%;
  display: flex;
  flex-direction: column; }

.versions-container_actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 7px 4px; }

.versions-container_actions_icon {
  cursor: pointer; }

.versions-container_table .radio-container div:not(.radio-container_radio_custom_inner_circle),
.versions-container_table .radio-container {
  background: transparent !important;
  color: #fff; }

.versions-container_table .radio-container .radio-container_radio_custom {
  margin-right: 15px !important; }

.video-details-container_row_column.two-columns {
  flex: 2 !important; }

.video-details-container_row_column .dropdown-ui-container {
  width: 100%; }
  .video-details-container_row_column .dropdown-ui-container_label-container_label {
    color: #8D919A; }

.video-details-container_row_column .toggle-ui-container_label {
  color: #8D919A; }

.commentslayer-content-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  align-items: center; }

.commentslayer-content-container_segment {
  position: absolute;
  background: #bd10e0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center; }

.markupslayer-content-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  align-items: center; }

.markupslayer-content-container_segment {
  position: absolute;
  background: #1F72F2;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center; }

.playhead-container {
  position: absolute;
  /* Height and top calculated based on container paddings */
  /* and scale layer height values */
  height: calc(100% - 55px);
  top: 45px;
  z-index: 5;
  cursor: pointer; }

.playhead-container_marker {
  width: 1.5px;
  background-color: red;
  height: 100%; }

.playhead-container_icon {
  position: absolute;
  left: -2px;
  top: -9px; }

.programtimingslayer-content-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  align-items: center; }

.programtimingslayer-content-container_segment {
  position: absolute;
  background: #B8E986;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center; }

.scroll-container {
  overflow: hidden; }

.subtitleslayer-container {
  position: relative; }
  .subtitleslayer-container_label {
    color: #8194B5;
    height: 25px !important; }
  .subtitleslayer-container .timelinerow-container:not(:last-child) {
    border-bottom: none; }
  .subtitleslayer-container .timelinerow-container_label {
    padding-top: 0px; }

.subtitleslayer-content-container_content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;
  align-items: center; }

.subtitleslayer-content-container_content.loading {
  height: 25px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  cursor: default;
  align-items: center; }

.subtitleslayer-content-container_content_item {
  position: absolute;
  background: #0AB3A0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center; }

.subtitleslayer-content-container_content_item_text {
  color: #fff;
  font-family: Helvetica, Arial, sans-serif; }

.subtitleslayer-content-container_content_item_text.hidden {
  display: none; }

.subtitleslayer-label {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .subtitleslayer-label_radio .radio-container_radio_title {
    letter-spacing: 0 !important; }

.audiolayer-container {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .audiolayer-container_label {
    color: #8194B5;
    height: 25px !important; }

.audiolayer-content-container {
  margin-top: 25px; }

.audiolayer-label-container .assets-list {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-end; }
  .audiolayer-label-container .assets-list_content {
    padding-top: 0px; }
    .audiolayer-label-container .assets-list_content_checkboxes {
      padding-top: 0px;
      min-height: unset !important;
      display: flex; }
      .audiolayer-label-container .assets-list_content_checkboxes .configuration-radio-container .radio-container_radio {
        width: 140px; }
        .audiolayer-label-container .assets-list_content_checkboxes .configuration-radio-container .radio-container_radio_title {
          letter-spacing: 0; }
      .audiolayer-label-container .assets-list_content_checkboxes .configuration-radio-container_audio-channels {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-left: 0px; }
        .audiolayer-label-container .assets-list_content_checkboxes .configuration-radio-container_audio-channels > .checkbox-container {
          width: 125px; }
          .audiolayer-label-container .assets-list_content_checkboxes .configuration-radio-container_audio-channels > .checkbox-container_checkbox .radio-container_radio_title {
            letter-spacing: 0; }
          .audiolayer-label-container .assets-list_content_checkboxes .configuration-radio-container_audio-channels > .checkbox-container svg {
            margin-right: 0.5em; }

.audiolayer-content-container_content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  cursor: pointer;
  align-items: center;
  flex: 1;
  z-index: -10;
  margin-bottom: 5px; }

.audiolayer-content-container_content.loading {
  display: flex;
  justify-content: center;
  font-size: 12px;
  cursor: default;
  align-items: center;
  width: 100%;
  height: 100%;
  display: 'flex';
  text-align: center;
  height: 25px;
  margin-bottom: 5px; }

.audiolayer-content-container_content_item {
  position: absolute;
  background: #0AB3A0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center; }

.audiolayer-content-container_content_item_text {
  color: #fff;
  font-family: Helvetica, Arial, sans-serif; }

.audiolayer-content-container_content_item_text.hidden {
  display: none; }

.thumbnailslayer-content-container {
  overflow: hidden; }

.thumbnailslayer-content-container_scroll-area {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  cursor: pointer; }

.thumbnailslayer-content-container_scroll-area.loading {
  height: 30px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  cursor: default;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.07); }

.thumbnailslayer-content-container .thumbnail-container.scale {
  position: absolute;
  top: 0px; }

.thumbnailslayer-label-container {
  width: 100%; }

.timeline-timecode-container {
  display: flex;
  width: 100%;
  flex-direction: row; }

.timeline-timecode-container_timecode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.timeline-timecode-container_timecode:not(:first-of-type) {
  margin-left: 25px; }

.timeline-timecode-container_timecode_input {
  margin-left: 5px;
  padding: 4px;
  border: 1px solid #4a4d55;
  outline: none;
  background: #24262c;
  border-radius: 2px; }

.timeline-timecode-container_timecode_input .time-picker {
  margin-right: -12px; }

.timeline-timecode-container_timecode_input .time-picker > input {
  font-size: 14px; }

.timeline-timecode-container_timecode_input .time-picker > .arrow-buttons {
  margin-left: 8px; }

.timeline-timecode-container_timecode.duration {
  margin-left: 15px; }

.timeline-timecode-container_timecode.duration .timeline-timecode-container_timecode_button {
  font-size: 13px; }

.timeline-timecode-container_timecode.duration .timeline-timecode-container_timecode_input {
  font-size: 13px;
  border: none;
  background: transparent;
  border-radius: 0;
  padding: 0;
  margin-left: 8px; }

.timeline-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: transparent;
  color: #fff;
  position: relative; }
  .timeline-container.modal-button {
    padding-bottom: 68px !important; }
  .timeline-container_layer {
    min-height: 40px;
    display: flex;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    /* Remove this border rule once the components will be used */
    border: 1px solid rgba(255, 255, 255, 0.07); }
  .timeline-container_conformance-button {
    width: 100%;
    height: 68px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0px; }
    .timeline-container_conformance-button_button {
      width: auto; }

.timeline-instance-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer; }
  .timeline-instance-container_header {
    box-sizing: border-box;
    height: 37px;
    background-color: #0E1115;
    display: flex;
    align-items: center;
    padding: 0 14.5px 0 10px;
    border-bottom: 1px solid rgba(129, 148, 181, 0.3); }
    .timeline-instance-container_header_title {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-start;
      flex: 1; }
      .timeline-instance-container_header_title_text {
        margin-left: 10px;
        color: #ffffff;
        max-width: 570px; }
    .timeline-instance-container_header_actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%; }
      .timeline-instance-container_header_actions_icon:not(:first-of-type) {
        margin-left: 20px; }
      .timeline-instance-container_header_actions_icon.rotate > svg {
        transform: rotate(90deg); }
  .timeline-instance-container_content {
    display: none;
    width: 100%;
    flex-direction: column;
    opacity: 0.3;
    transition: opacity 0.5s linear; }
    .timeline-instance-container_content.visible {
      display: flex;
      opacity: 1; }
    .timeline-instance-container_content.empty {
      align-items: center;
      justify-content: center;
      min-height: 100px; }

.timelinerow-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  border-bottom: 1px solid rgba(129, 148, 181, 0.3); }

.timelinerow-container_label {
  width: 160px;
  box-sizing: border-box;
  color: #8194B5; }
  .timelinerow-container_label > .aligned {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    box-sizing: border-box; }

.timelinerow-container_content {
  flex: 1;
  overflow: hidden; }

.timelinescale-container .timelinerow-container_label {
  padding: 0 1em 0 1em; }

.timescale-content_container,
.timescale-content_content {
  height: 100%; }

.timescale-content_content {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start; }

.timescale-content_content_second {
  border-left: 1px solid #fff;
  box-sizing: border-box;
  position: relative; }

.timescale-content_content_second_time {
  font-size: 12px;
  position: absolute;
  left: -33px; }

.timescale-label-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 35px;
  align-items: center;
  position: relative; }

.timescale-label-container_right {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center; }

.timescale-label-container_right > .custom-button:not(:last-child) {
  margin-right: 5px; }

.timescale-label-container_left {
  display: flex;
  height: 100%;
  align-items: center; }

.timescale-label-container_left > svg {
  cursor: pointer; }

.timescale-label-container_right button:disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.timescale-label-container_scale {
  flex: 1;
  display: flex;
  justify-content: flex-end; }

.timeline-settings-container_popup {
  background: #1b1e24;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  width: 160px;
  border: 1.5px solid #1f6fe9;
  color: #fff;
  z-index: 5;
  position: absolute;
  left: 46px;
  top: -13px; }
  .timeline-settings-container_popup_content {
    display: flex;
    flex-direction: column; }
    .timeline-settings-container_popup_content_settings {
      display: flex;
      flex-direction: column;
      padding-top: 10px; }
      .timeline-settings-container_popup_content_settings .checkbox-container_checkbox > input[type='checkbox'] + .checkbox-container_checkbox_custom {
        width: 12px;
        height: 12px;
        box-sizing: border-box; }

.timeline-settings-container_arrow {
  border-right: 16px solid transparent;
  border-top: 12px solid transparent;
  position: relative;
  z-index: 6;
  border-bottom: 12px solid transparent;
  position: absolute;
  left: 30px; }
  .timeline-settings-container_arrow::before {
    border-right: 15px solid #1f6fe9;
    border-top: 12px solid transparent;
    bottom: -10px;
    content: "";
    position: absolute;
    right: -16px;
    border-bottom: 12px solid transparent; }
  .timeline-settings-container_arrow::after {
    border-right: 14px solid #1b1e24;
    border-top: 11px solid transparent;
    bottom: -9.5px;
    content: "";
    position: absolute;
    right: -18px;
    border-bottom: 11px solid transparent; }

.timeline-tooltip-container_content {
  position: absolute;
  z-index: 7;
  min-width: 150px;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 2px;
  font-family: Helvetica, Arial, sans-serif;
  color: #000;
  padding: 8px;
  font-size: 12px;
  max-width: 300px;
  min-width: 191px; }

.timeline-tooltip-container_content_loop {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 12px;
  height: 12px;
  cursor: pointer; }

.timeline-tooltip-container_content_info-panel_title {
  font-weight: bold;
  width: 128px;
  overflow: hidden;
  text-overflow: ellipsis; }

.timeline-tooltip-container_content_info-panel_interval {
  display: flex;
  flex-direction: row;
  font-weight: bold; }

.timeline-tooltip-container_content_content {
  padding-top: 10px; }

.timeline-tooltip-container_arrow {
  position: absolute;
  z-index: 7;
  border-top: 15px solid rgba(255, 255, 255, 0.75);
  border-left: 16px solid transparent;
  border-right: 16px solid transparent; }

.timeline-tooltip-container_content_info-panel {
  display: flex;
  flex-direction: column; }

.timeline-tooltip-container_content_avatar {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 3px; }

.timeline-tooltip-container_content_avatar > img {
  width: 100%; }

.timeline-tooltip-container_content_comment-date,
.timeline-tooltip-container_content_duration {
  padding-top: 5px;
  font-size: 11px; }
